<template>
    <div>
        <v-progress-circular
        v-if="loading"
        indeterminate
        color="#8e84c0"
        class="loading-spinner"
        ></v-progress-circular>
        <div class="page-background" :style="getPageBackgroundStyle()" v-if="!loading">
            <div class="background" :style="getBackgroundStyle()" :class="{'background-opacity': true}">
                <v-alert
                dense
                color="info"
                class="col-12 text-center white--text mb-0"
                v-if="!loading && editor"
                >
                    <v-btn outlined color="white" class="mt-0 rounded-lg p-3 mr-3 ml-n3" small @click="returnPanel()">
                        <v-icon left color="white " size="18px">
                            mdi-keyboard-return
                        </v-icon>
                        {{$t("harmony.back")}}
                    </v-btn>

                    <v-btn outlined color="white" class="mt-0 rounded-lg p-3 mr-3 ml-n0" small @click="goHarmony('/'+harmony.slug)">
                        <v-icon center color="white " size="18px">
                            mdi-web
                        </v-icon>
                    </v-btn>
                    <span v-html="$t('harmony.edit')"></span>
                </v-alert>
                <v-container v-if="!loading" class="mt-0 mb-0 fill-height">
                    <v-row align="center" justify="center">
                        <v-col class="col-12 col-md-7">
                        <v-row no-gutters align="center" justify="center">
                            <v-navigation-drawer
                            v-model="menu_editor"
                            app
                            absolute
                            right
                            temporary
                            :style="{ height: getHeight,  overflowY: 'auto', maxHeight: maxHeight}"
                            class="floating-drawer rounded-xl mt-2 mr-2 "
                            v-if="editor"
                            >

                                <div class="px-4">
                                <template v-if="select == 'logo'">
                                    <div class="d-flex justify-space-between">
                                        <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.add-photoUser")}}</p>
                                        <v-btn v-if="harmony.logo_path" outlined color="#9BA9BD" class="mt-3 rounded-lg p-3" small @click="removeLogo(harmony.id)">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                    <ImageUpload
                                    uploadEndpoint="/upload-site-logo"
                                    :siteId="harmony.id"
                                    :selected="select"
                                    :previewUrl="harmony.logo_path"
                                    @image-uploaded="handleImageUploaded"
                                    />

                                    <div class="d-flex justify-space-between mt-0">
                                        <p class="text-left menu-text mt-0 mb-2" v-if="selectBackground == 1">{{$t("harmony.add-backgroundColor")}}</p>
                                        <p class="text-left menu-text mt-4 mb-2" v-else>{{$t("harmony.add-backgroundImage")}}</p>
                                        <v-btn   outlined color="#9BA9BD" class="mt-3 rounded-lg p-3" small @click="removeBackground(site_setting.page.background)">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                    <ImageUpload
                                    uploadEndpoint="/upload-background"
                                    selected="background"
                                    cardSelected="page-background"
                                    :siteId="harmony.id"
                                    :previewUrl="site_setting.page.background"
                                    @image-uploaded="handleImageUploaded"
                                    v-if="selectBackground == 2 || site_setting.page.background"
                                    />

                                    <v-color-picker
                                    :key="selectBackground + site_setting.page.background"
                                    v-model="site_setting.page.color"
                                    dot-size="25"
                                    hide-inputs
                                    hide-mode-switch
                                    swatches-max-height="200"
                                    canvas-height="100"
                                    v-if="selectBackground == 1 && !site_setting.page.background"
                                    ></v-color-picker>

                                    <v-row no-gutters align="start" justify="start">
                                        <v-btn
                                        @click="selectBackground = 1"
                                        outlined
                                        elevation="0"
                                        color="#9BA9BD"
                                        class="rounded-lg m-0 mt-3 p-3 mr-2"
                                        small
                                        v-if="!site_setting.page.background"
                                        >
                                        {{$t("harmony.add-color")}}
                                        </v-btn>

                                        <v-btn
                                        @click="selectBackground = 2"
                                        outlined
                                        elevation="0"
                                        color="#9BA9BD"
                                        class="rounded-lg m-0 mt-3 p-3 mr-2"
                                        small
                                        >
                                        {{$t("harmony.add-image")}}
                                        </v-btn>
                                    </v-row>

                                    <div class="d-flex justify-space-between">
                                        <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.add-backgroundPatterns")}}
                                            <span style="color: #8e84c0">{{site_setting.page.pattern}}</span></p>

                                            <v-btn v-if="site_setting.page.pattern" outlined color="#9BA9BD" class="mt-3 rounded-lg p-3" small @click="site_setting.page.pattern = ''">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>

                                    </div>
                                    <div class="frame-div patterns-div" >
                                        <v-row no-gutters>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'puntos' }" @click="setPattern('puntos')">
                                                <img src="@/assets/iconsback/puntos.svg" alt="Puntos" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'calaberas' }" @click="setPattern('calaberas')">
                                                <img src="@/assets/iconsback/calaberas.svg" alt="Calaberas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'calavazas' }" @click="setPattern('calavazas')">
                                                <img src="@/assets/iconsback/calavazas.svg" alt="Calavazas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'corazones' }" @click="setPattern('corazones')">
                                                <img src="@/assets/iconsback/corazones.svg" alt="Corazones" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'cuadros' }" @click="setPattern('cuadros')">
                                                <img src="@/assets/iconsback/cuadros.svg" alt="Cuadros" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'flor' }" @click="setPattern('flor')">
                                                <img src="@/assets/iconsback/flor.svg" alt="Flor" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'flor2' }" @click="setPattern('flor2')">
                                                <img src="@/assets/iconsback/flor2.svg" alt="Flor 2" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'happyface' }" @click="setPattern('happyface')">
                                                <img src="@/assets/iconsback/happyface.svg" :class="{ selected: site_setting.page.pattern === 'puntos' }" alt="Happy Face" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'ondas' }" @click="setPattern('ondas')">
                                                <img src="@/assets/iconsback/ondas.svg" alt="ondas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'lunas' }" @click="setPattern('lunas')">
                                                <img src="@/assets/iconsback/lunas.svg" alt="lunas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'estrellas' }" @click="setPattern('estrellas')">
                                                <img src="@/assets/iconsback/estrellas.svg" alt="estrellas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'play' }" @click="setPattern('play')">
                                                <img src="@/assets/iconsback/play.svg" alt="play" class="icon">
                                            </div>
                                        </v-row>
                                    </div>

                                    <v-btn
                                    outlined
                                    color="#8e84c0"
                                    class="text-black rounded-md py-4 px-10 mt-6"
                                    small
                                    :loading="loadingSave"
                                    :disabled="loadingSave"
                                    @click="saveSettings()
                                    ">
                                        <v-icon center>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>

                                </template>

                                <template v-if="select == 'title'">
                                    <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.add-colorNick")}}</p>
                                    <v-color-picker
                                    v-model="site_setting.profile.title_color"
                                    dot-size="25"
                                    hide-inputs
                                    hide-mode-switch
                                    swatches-max-height="200"
                                    ></v-color-picker>

                                    <v-btn
                                    outlined
                                    color="#8e84c0"
                                    class="text-black mt-0 rounded-md py-4 px-10"
                                    small
                                    :loading="loadingSave"
                                    :disabled="loadingSave"
                                    @click="saveSettings()
                                    ">
                                        <v-icon center>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>
                                </template>

                                <template v-if="select == 'description'">
                                    <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.add-colorDescription")}}</p>
                                    <v-color-picker
                                    v-model="site_setting.profile.desc_color"
                                    dot-size="25"
                                    hide-inputs
                                    hide-mode-switch
                                    swatches-max-height="200"
                                    ></v-color-picker>

                                    <p class="text-left menu-text mt-4 mb-2">
                                        {{$t("harmony.add-description")}}
                                    </p>

                                    <div class="wrapper">
                                        <v-textarea
                                            v-model="site_setting.profile.description"
                                            color="#9BA9BD"
                                            :placeholder="$t('harmony.enter')"
                                            outlined
                                            dense
                                            counter
                                            maxlength="100"
                                            rows="3"
                                            @input="handleDescriptionInput"
                                        ></v-textarea>

                                        <emoji-picker style="width: 230px !important;" @emoji="append" :search="search">
                                            <button
                                                class="emoji-invoker"
                                                slot="emoji-invoker"
                                                slot-scope="{ events: { click: clickEvent } }"
                                                @click.stop="clickEvent"
                                            >
                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-grey">
                                                <path d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
                                                </svg>
                                            </button>
                                            <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                                                <div class="emoji-picker">
                                                <div style="width: 50px !important;" class="emoji-picker__search">
                                                    <input type="text" v-model="search" v-focus>
                                                </div>
                                                <div style="width: 220px !important;padding: 0px !important;">
                                                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                                                    <h5>{{ category }}</h5>
                                                    <div class="emojis">
                                                        <span
                                                        v-for="(emoji, emojiName) in emojiGroup"
                                                        :key="emojiName"
                                                        @click="insert(emoji)"
                                                        :title="emojiName"
                                                        >{{ emoji }}</span>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </emoji-picker>

                                    <v-btn
                                    outlined
                                    color="#8e84c0"
                                    class="text-black mb-4 mt-3 rounded-md py-4 px-10"
                                    small
                                    :loading="loadingSave"
                                    :disabled="loadingSave"
                                    @click="saveSettings()
                                    ">
                                        <v-icon center>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>
                                    </div>



                                </template>

                                <template v-if="select == 'card'">
                                    <div class="d-flex justify-center ">
                                        <p class="text-left menu-text mt-4 mb-2 mr-2">{{$t("harmony.upload")}}</p>
                                        <v-btn v-if="hasImages(site_setting.galleries[`card${cardSelect}`])" outlined color="#9BA9BD" class=" mt-3 rounded-lg  mr-2" small @click="addNewImagesGallery()"  style="padding: 0; min-width: 30px !important;">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        <v-btn  v-if="image_or_video === 4" outlined color="#9BA9BD" class=" mt-3 rounded-lg  mr-2" small @click="addSchedule = true" :loading="loadingSave" style="padding: 0; min-width: 30px !important;">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-plus
                                                </v-icon>
                                            </v-btn>

                                        <v-row no-gutters align="start" justify="start">
                                            <v-col cols="auto" class="col">
                                                <v-select
                                                v-model="image_or_video"
                                                :items="itemsForUpload"
                                                item-text="name"
                                                item-value="id"
                                                outlined
                                                hide-details="auto"
                                                dense
                                                color="#9BA9BD"
                                                :disabled="isDisabled"
                                                class="my-custom-select rounded-lg m-0 mt-3 p-3 mr-2"
                                            />
                                            </v-col>
                                        </v-row>
                                        <v-btn v-if="site_setting.backgrounds[`card${this.cardSelect}`]" outlined color="#9BA9BD" class="mt-3 rounded-lg " small @click="removeBackground(site_setting.backgrounds[`card${cardSelect}`])" style="padding: 0; min-width: 30px !important;">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>
                                        <v-btn v-if="site_setting.videos[`card${this.cardSelect}`]" outlined color="#9BA9BD" class="mt-3 rounded-lg " small @click="removeVideo(site_setting.videos[`card${cardSelect}`])"  style="padding: 0; min-width: 30px !important;">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>

                                        <v-btn v-if="hasImages(site_setting.galleries[`card${cardSelect}`])" outlined color="#9BA9BD" class="mt-3 rounded-lg " small @click="removeGallery(site_setting.galleries[`card${cardSelect}`].id)"  style="padding: 0; min-width: 30px !important;">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>

                                    </div>


                                    <ImageUpload
                                    v-if="image_or_video === 1"
                                    uploadEndpoint="/upload-background"
                                    :selected="select"
                                    :cardSelected="'card'+cardSelect"
                                    :siteId="harmony.id"
                                    :previewUrl="site_setting.backgrounds[`card${this.cardSelect}`]"
                                    @image-uploaded="handleImageUploaded"
                                    />

                                    <VideoUpload
                                    v-if="image_or_video === 2"
                                    uploadEndpoint="/upload-video"
                                    :selected="select"
                                    :cardSelected="'card'+cardSelect"
                                    :siteId="harmony.id"
                                    :previewUrl="site_setting.videos[`card${this.cardSelect}`]"
                                    @video-uploaded="handleVideoUploaded"
                                    />

                                    <ImageUploadGallery
                                    v-if="image_or_video === 3"
                                    ref="imageUploadGallery"
                                    uploadEndpoint="/upload-gallery"
                                    :selected="select"
                                    :cardSelected="'card'+cardSelect"
                                    :siteId="harmony.id"
                                    :updatedGallery="galleryUpdated"
                                    :galleryId="site_setting.galleries[`card${this.cardSelect}`].id"
                                    @images-uploaded="galleryUpdated = false"
                                    :previewUrl="site_setting.galleries[`card${this.cardSelect}`].images[0]"
                                    @image-uploaded-gallery="handleImageUploaded"
                                    />


                                    <ImageUpload
                                    v-if="image_or_video === 4"
                                    uploadEndpoint="/upload-background"
                                    :selected="select"
                                    :cardSelected="'card'+cardSelect+'/4'"
                                    :siteId="harmony.id"
                                    :previewUrl="site_setting.backgrounds[`card${this.cardSelect}`]"
                                    @image-uploaded="handleImageUploaded"
                                    />
                                    <ScheduleUpload
                                    v-if="image_or_video === 4"
                                    ref="UploadSchedule"
                                    uploadEndpoint="/upload-schedule"
                                    :selected="select"
                                    :cardSelected="'card'+cardSelect"
                                    :siteId="harmony.id"
                                    :scheduleId="site_setting.schedules[`card${this.cardSelect}`].id"
                                    :schedule="site_setting.schedules[`card${this.cardSelect}`].schedule"
                                    @updated-schedule="handleImageUploaded"
                                    @click="saveSettings()"
                                    />

                                    <div class="d-flex justify-space-between align-items-center mt-0" v-if="image_or_video != 3 && image_or_video !=4 || image_or_video != 3 && image_or_video !=4 && !hasSchedule(site_setting.schedules[`card${cardSelect}`]) || image_or_video != 3 && image_or_video != 4 && !hasSchedule(site_setting.schedules[`card${cardSelect}`]) " >
                                        <p class="text-left menu-text mt-3 mb-0">
                                            {{$t("harmony.add-link")}} <br><span style="color: #8e84c0"> {{getPlatformName(site_setting.platforms[`card${this.cardSelect}`].name)}}</span>
                                        </p>
                                        <v-btn outlined color="#9BA9BD" class="mt-2 rounded-lg p-0" small @click="removePlatform()" v-if="getPlatformName(site_setting.platforms[`card${this.cardSelect}`].name)">
                                        <v-icon center color="#9BA9BD" size="18px">
                                            mdi-trash-can-outline
                                        </v-icon>
                                        </v-btn>
                                    </div>


                                    <div class="d-flex justify-center"   v-if="image_or_video != 3 && image_or_video !=4 || image_or_video != 3 && image_or_video !=4 && !hasSchedule(site_setting.schedules[`card${cardSelect}`]) || image_or_video != 3 && image_or_video != 4 && !hasSchedule(site_setting.schedules[`card${cardSelect}`])">
                                        <v-row no-gutters align="start" justify="start">
                                            <v-col cols="auto" class="col">
                                                <v-text-field
                                                v-model="platformSearch"
                                                outlined
                                                dense
                                                color="#9BA9BD"
                                                :rules="[rules.noUrlPlatform]"
                                                @input="showPlatforms = true"
                                                :placeholder="$t('harmony.search-platform')"
                                                maxlength="13"
                                                class="mt-2 mb-1"
                                            />
                                            </v-col>
                                        </v-row>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    outlined
                                                    color="#9BA9BD"
                                                    class="mt-2 rounded-lg d-flex align-center justify-center ml-2"
                                                    small
                                                    :min-width="10"
                                                    style="padding: 6px;"
                                                    :height="39"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-checkbox
                                                        v-model="showPlatformCard"
                                                        color="#9BA9BD"
                                                        :class="{'checkbox-on': showPlatformCard, 'checkbox-off': !showPlatformCard}"
                                                        class="ma-0 pa-0"
                                                        hide-details
                                                        off-icon="mdi-eye-off"
                                                        on-icon="mdi-eye"
                                                    ></v-checkbox>
                                                </v-btn>
                                            </template>
                                            <span>{{  showPlatformCard ?  $t('harmony.hide-platform') :  $t('harmony.show-platform')}}</span>
                                        </v-tooltip>

                                    </div>
                                    <div class="frame-div platforms-div mt-n3" :class="getBgPlatforms(colorPlatform)" v-show="showPlatforms && image_or_video != 3  &&  !hasSchedule(site_setting.schedules[`card${cardSelect}`]) || showPlatforms && image_or_video != 3  &&  hasSchedule(site_setting.schedules[`card${cardSelect}`]) || showPlatforms && image_or_video != 3  &&  image_or_video != 4">
                                        <div v-for="platform in filteredPlatforms" :key="platform.name">

                                                <h1  v-if="!getPlatformImage(platform)" @click="selectPlatform(platform.name)" :style="{ color: colorPlatform === 2 ? 'white' : 'black' }" class="newPlatform">
                                                    {{ platform.name }}
                                                </h1>

                                            <img
                                            v-if="getPlatformImage(platform)"
                                            :src="getPlatformImage(platform)"
                                            class="platforms mt-1"
                                            @click="selectPlatform(platform.name)"
                                            >
                                        </div>

                                        <!-- Botón para agregar plataforma si no hay coincidencias -->
                                        <div v-if="filteredPlatforms.length === 0" class="mt-4">
                                            <v-btn outlined color="#8e84c0" @click="addPlatform()" class="py-5 px-3" :disabled="!validPlatform">
                                                <v-icon left>mdi-plus</v-icon>
                                                {{$t("harmony.add-platform")}}
                                            </v-btn>
                                        </div>
                                    </div>
                                    <v-row no-gutters align="start" justify="start" v-show="showPlatforms  && image_or_video != 3">
                                        <v-col cols="4">
                                            <v-btn
                                            @click="changeColor(1)"
                                            outlined
                                            elevation="0"
                                            color="#9BA9BD"
                                            class="rounded-lg p-2 mt-2"
                                            small
                                            >
                                            {{$t("harmony.black")}}
                                            </v-btn>
                                        </v-col>

                                        <v-col cols="4">
                                            <v-btn
                                            @click="changeColor(2)"
                                            outlined
                                            elevation="0"
                                            color="#9BA9BD"
                                            class="rounded-lg p-2 mt-2"
                                            small
                                            >
                                            {{$t("harmony.white")}}
                                            </v-btn>
                                        </v-col>

                                        <v-col cols="4">
                                            <v-btn
                                            @click="changeColor(3)"
                                            outlined
                                            elevation="0"
                                            color="#9BA9BD"
                                            class="rounded-lg p-2 mt-2"
                                            small
                                            >
                                            Color
                                            </v-btn>
                                        </v-col>
                                        </v-row>

                                        <div v-show="showNick  && image_or_video != 3  &&  !hasSchedule(site_setting.schedules[`card${cardSelect}`])">
                                        <p class="text-left menu-text mt-3 mb-1">
                                            {{ $t('harmony.your-nick', { platform: getPlatformName(site_setting.platforms[`card${this.cardSelect}`].name) }) }}
                                        </p>
                                    <v-text-field
                                        v-model="selectedCard.nick"
                                        color="#9BA9BD"
                                        :placeholder="$t('harmony.enter')"
                                        outlined
                                        dense
                                        :rules="[rules.noUrl]"
                                    ></v-text-field>
                                    </div>

                                    <div v-if="selectNewPlatform != ''" class="mt-n3">
                                        <p class="text-left menu-text mb-1">{{$t("harmony.link-url")}}</p>
                                        <v-text-field
                                        v-model="selectedNewPlatform.url"
                                            color="#9BA9BD"
                                            :placeholder="$t('harmony.enter')"
                                            outlined
                                            dense
                                            >
                                        </v-text-field>
                                    </div>

                                    <div v-if="showUrl2 && image_or_video != 3  &&  !hasSchedule(site_setting.schedules[`card${cardSelect}`])" class="mt-n3">
                                        <p class="text-left menu-text mb-1">
                                            {{ selectedCard.name.split('/')[0].toUpperCase() == 'YOUTUBE'
                                                ? $t('harmony.optional-video-link')
                                                : selectedCard.name.split('/')[0].toUpperCase() == 'SPOTIFY'
                                                ? $t('harmony.optional-spotify-link')
                                                : $t('harmony.optional-post-link')
                                            }}
                                          </p>
                                        <v-text-field
                                            v-model="selectedCard.url2"
                                            color="#9BA9BD"
                                           :placeholder="$t('harmony.enter')"
                                            outlined
                                            dense
                                            >
                                        </v-text-field>

                                    </div>

                                    <div :style="buttonContainerStyle">
                                    <v-btn
                                    outlined
                                    color="#8e84c0"
                                    class="text-black rounded-md py-4 px-10 mb-4"
                                    small
                                    :disabled="!valid || loadingSave"
                                    :loading="loadingSave"
                                    @click="saveSettings()
                                    ">
                                        <v-icon center>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>
                                    </div>


                                    <v-dialog v-model="addSchedule" max-width="350px" min-width="340px"  style="min-width: 340px !important;">
                                        <v-card class="px-4 rounded-xxl">
                                            <p class="text-left menu-text pt-5 pb-2 mb-0 text-subtitle-1">
                                                {{$t("harmony.add-schedule")}}
                                            </p>
                                            <v-select
                                            v-model="name_day"
                                            ref="filteredDayOptions"
                                            :items="filteredDayOptions"
                                            item-value="id"
                                            item-text="name"
                                            outlined
                                            dense
                                            color="#9BA9BD"
                                            :rules="[rules.required]"
                                            :placeholder="$t('harmony.select-day')"
                                            class="mt-2 mb-1"
                                          />

                                            <v-text-field
                                            v-model="urlSchedule"
                                            ref="urlSchedule"
                                            outlined
                                            dense
                                            color="#9BA9BD"
                                            :rules="[rules.url]"
                                            :placeholder="$t('harmony.url-schedule')"
                                            class="mt-2 mb-1"
                                            />
                                            <v-row>
                                                <v-col class="col-6 py-0 d-flex flex-column ">
                                                    <v-container class="pa-0">
                                                        <v-text-field
                                                            ref="selectedStartHour"
                                                            :label="$t('harmony.start-time')"
                                                            v-model="selectedStartHour"
                                                            type="time"
                                                            :rules="[rules.required]"
                                                            outlined
                                                            required
                                                            dense
                                                        />
                                                      </v-container>
                                                </v-col>

                                                <v-col class="col-6 py-0 d-flex flex-column ">
                                                    <v-container class="pa-0">
                                                        <v-text-field
                                                            ref="selectedEndHour"
                                                            :label="$t('harmony.end-time')"
                                                            v-model="selectedEndHour"
                                                            type="time"
                                                            :rules="[rules.required, rules.endTime]"
                                                            error-messages=""
                                                            outlined
                                                            required
                                                            dense
                                                        />
                                                      </v-container>
                                                </v-col>
                                            </v-row>

                                            <v-btn
                                                :disabled="!isFormValid"
                                                :loading="loadingSave"
                                                outlined
                                                color="#8e84c0"
                                                class="text-black mt-0 rounded-md py-4 px-10 mt-12 mb-5"
                                                small
                                                @click="saveSchedule()">
                                                <v-icon center>
                                                mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        </v-card>
                                    </v-dialog>

                                </template>

                                <template v-if="select == 'socials'">
                                    <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.choose-style")}}<span style="color: #8e84c0">{{getSocialStyle(site_setting.style_socials)}}</span></p>
                                    <div class="socials-div frame-div rounded-xl px-2 d-flex items-center justify-center">
                                        <div
                                            class="d-flex justify-center align-items-center flex-column px-2"
                                            v-for="(social, index) in socialsBar"
                                            :key="index"
                                            @click="setSocialStyle(index + 1)"
                                        >
                                            <img :src="require(`@/assets/socials/${index + 1}/${social}.svg`)" class="socials-icon  ">
                                            <div v-if="selectedSocial === index + 1" class="d-flex justify-center align-items-center mt-1">
                                                <v-icon class="selected-icon" size="small">mdi-check-circle</v-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.add-socialNetworks")}}</p>
                                    <v-text-field
                                            v-for="(item, key) in socialItems"
                                            :key="countSocials() > 0 ? item.id : key"
                                            v-model="item.nick"
                                            @input="handleInput(countSocials() > 0 ? item.id : key)"
                                            :append-outer-icon="changes[countSocials() > 0 ? item.id : key] ? 'mdi-send-check-outline' : 'mdi-trash-can-outline'"
                                            @click:append-outer="changes[countSocials() > 0 ? item.id : key] ? saveSocial(item) : deleteSocial(item)"
                                            color="#9BA9BD"
                                            :placeholder="$t('harmony.user-nick')"
                                            outlined
                                            dense
                                            >
                                            <template v-slot:prepend>
                                                <v-img class="icon-redes contain" :src="require(`@/assets/socials/${site_setting.style_socials}/${item.name}.svg`)"></v-img>
                                            </template>
                                    </v-text-field>
                                    <v-btn
                                        :disabled="countSocials() >= 10 || loadingSave"
                                        :loading="loadingSave"
                                        outlined
                                        color="#8e84c0"
                                        class="text-black mt-0 rounded-md py-4 px-10"
                                        small
                                        @click="dialog = true">
                                        <v-icon center>
                                        mdi-plus
                                        </v-icon>
                                    </v-btn>
                                    <v-dialog v-model="dialog" max-width="300px">
                                        <v-card>
                                            <v-card-title class="text-h6 menu-text px-4">{{$t("harmony.add-new-socialNetwork")}}</v-card-title>
                                            <v-card-text class="pb-0 px-4">
                                                <v-autocomplete
                                                    :items="list_socials"
                                                    v-model="newSocial.name"
                                                    :label="$t('harmony.select-socialNetwork')"
                                                    color="#9BA9BD"
                                                    class="mt-2"
                                                    outlined
                                                    dense
                                                    item-value="value"
                                                    item-text="name"
                                                    auto-select-first
                                                    :rules="[rules.required]"
                                                />
                                                <v-text-field
                                                    color="#9BA9BD"
                                                    :placeholder="getSocialPlaceholder(newSocial.name)"
                                                    outlined
                                                    dense
                                                    v-model="newSocial.nick"
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-card-text>
                                            <v-card-actions class="justify-center pb-4">
                                                <v-btn outlined color="#8e84c0" class="text-black mt-0 rounded-md py-4 px-6" small     :loading="loadingSave" :disabled="loadingSave  || !validNick" @click="saveSocial(newSocial)">
                                                    <v-icon center>
                                                        mdi-check-bold
                                                    </v-icon>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                            </template>


                            <template v-if="select == 'add-card'">

                                <h1 class="text-center menu-text mt-4 ">Crear una nueva tarjeta</h1>

                                <div class="d-flex justify-center ">

                                        <p class="text-left menu-text mt-4 mb-2 mr-3">Tamaño:</p>
                                        <v-row no-gutters align="start" justify="start">
                                            <v-col cols="auto" class="col">
                                                <v-select
                                                v-model="size"
                                                :items="sizes"
                                                item-text="name"
                                                item-value="value"
                                                outlined
                                                hide-details="auto"
                                                dense
                                                color="#9BA9BD"
                                                class="my-custom-select rounded-lg m-0 mt-3 p-3 mr-2"
                                            />
                                            </v-col>
                                        </v-row>

                                    </div>
                                    <div class="d-flex justify-center ">
                                    <p class="text-left menu-text mt-4 mb-2 mr-2">Posicion:</p>
                                    <v-row no-gutters align="start" justify="start">
                                        <v-col cols="auto" class="col">
                                            <v-select
                                            v-model="position"
                                            :items="positions"
                                            item-text="name"
                                            item-value="value"
                                            outlined
                                            hide-details="auto"
                                            dense
                                            color="#9BA9BD"
                                            :disabled="true"

                                            class="my-custom-select rounded-lg m-0 mt-3 p-3 mr-2"
                                        />
                                        </v-col>
                                    </v-row>

                                </div>


                                <v-btn
                                    outlined
                                    color="#8e84c0"
                                    class="text-black rounded-md py-4 px-10 mt-6"
                                    small
                                    :loading="loadingSave"
                                    :disabled="loadingSave"
                                    @click="addNewCard()">
                                        <v-icon center>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>


                            </template>

                        </div>
                            </v-navigation-drawer>

                            <!-- Logo -->
                            <div
                            class="rounded-xl card-shadow-logo"
                            :class="getBase('logo')"
                            @click="editor ? (menu_editor = true, select = 'logo') : null"
                            >
                            <v-img
                                v-if="harmony && harmony.logo_path"
                                :src="harmony.logo_path"
                                max-width="100%"
                                max-height="100%"
                                :class="getBase('logo')"
                                class="mt-0 px-0 mx-0 rounded-xl image-preview"
                            />
                            <div v-else>
                                <v-icon v-if="editor" center color="#C9C9C9" size="40px" class="mt-5 mt-md-8">
                                    mdi-pencil-box
                                </v-icon>
                            </div>
                            </div>

                            <div class="col-12 text-center">
                            <h1 class="mt-1 title-harmony card-text-shadow">
                                <span
                                :class="getTitle()"
                                @click="editor ? (menu_editor = true, select = 'title') : null"
                                :style="{ color: site_setting.profile.title_color }"
                                >{{ harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1) }}</span>

                                <v-icon
                                center
                                :color="checkPageColor() ? 'white' : 'rgb(184 184 184)'"
                                size="30px"
                                class="mb-1"
                                :class="getTitle()"
                                @click="editor ? (menu_editor = true, select = 'title') : null"
                                v-if="editor"
                                >
                                mdi-pencil-box
                                </v-icon>
                            </h1>
                            <p v-if="harmony.description">
                                <span
                                :class="getTitle()"
                                :style="{ color: site_setting.profile.desc_color }"
                                class="card-text-shadow responsive-text"
                                @click="editor ? (menu_editor = true, select = 'description') : null"
                                v-html="addLineBreaks(this.site_setting.profile.description)"
                                >
                                </span>
                                <v-icon
                                center
                                :color="checkPageColor() ? 'white' : 'rgb(184 184 184)'"
                                size="30px"
                                class="mb-1"
                                :class="getTitle()"
                                @click="editor ? (menu_editor = true, select = 'description') : null"
                                v-if="editor"
                                >
                                mdi-pencil-box
                                </v-icon>
                            </p>

                            <v-icon
                            v-if="!harmony.description && editor"
                            center
                            :color="checkPageColor() ? 'white' : 'rgb(184 184 184)'"
                            size="30px"
                            class="mb-1"
                            :class="getTitle()"
                            @click="editor ? (menu_editor = true, select = 'description') : null"
                            >
                            mdi-pencil-box
                            </v-icon>
                            </div>

                            <v-row
                            no-gutters
                            align="start"
                            justify="center"
                            class="mt-3 sections-container"
                            >
                            <!-- Left section -->
                            <div id="left-section" class="sections mb-16">

                                <!-- Socials -->
                                 <div id="socials" class="mr-2 mb-4 rounded-xxl pl-2 pr-0 pr-md-0 py-0 card-shadow-socials" :class="getBase('socials', countSocials())" @click="menu_editor = true; select = 'socials'" v-if="countSocials() > 0 || editor">
                                    <v-row no-gutters justify="center">
                                         <span v-for="(item, key) in socials" :key="key">
                                            <a v-if="editor  ||   item.nick != ''" :href="editor ? false : formatUrl(item.name, item.nick)" class="mr-2 m-0 p-0">
                                                <img :src="getIconPath(item.name)" class="socials-icon2 mt-2"   :class="getIconSize()" :alt="key">
                                            </a>
                                        </span>
                                    </v-row>

                                    <v-icon v-if="editor && countSocials() == 0" center color="#C9C9C9" size="40px">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card1 -->
                                <div
                                    id="card1"
                                    class="card-container mr-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('card', site_setting.backgrounds.card1)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 1, selectPlatformCard(site_setting.platforms.card1.name) ) : null, navigateToCard(platforms_temp.card1, site_setting.videos.card1 != '' ? site_setting.videos.card1 : site_setting.backgrounds.card1,site_setting.schedules.card1.schedule)"
                                    v-if="site_setting.backgrounds.card1 || editor || site_setting.videos.card1 || site_setting.platforms.card1.name || hasImages(site_setting.galleries.card1) || hasSchedule(site_setting.schedules.card1)"
                                >

                                    <video
                                     v-if="site_setting.videos.card1"
                                    :src="site_setting.videos.card1"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="getBase('video')"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card1"
                                    :src="site_setting.backgrounds.card1"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="getBase('card')"
                                    class=" card mt-0 px-0 mx-0 rounded-xl"
                                    />

                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card1)"
                                        :images="site_setting.galleries.card1.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        galleryId="card1"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card1)"
                                    :image="site_setting.backgrounds.card1.path"
                                    :schedule="site_setting.schedules.card1.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card1"
                                    @click="saveSettings()"
                                    />


                                    <div v-if="site_setting.platforms.card1.show && !hasImages(site_setting.galleries.card1) && !hasSchedule(site_setting.schedules.card1)">
                                        <div v-if="!site_setting.platforms.card1.name.includes('/')"
                                        :class="(site_setting.platforms.card1.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card1.name }}</h1>
                                        </div>

                                        <div v-else>
                                            <img
                                            v-if="site_setting.platforms.card1.name"
                                            :src="backend_url+`/platforms/${site_setting.platforms.card1.name}`"
                                            class="platform-logo"
                                            />
                                        </div>

                                    </div>
                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card1 && !hasImages(site_setting.galleries.card1) && !hasSchedule(site_setting.schedules.card1)">
                                    mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card2 -->
                                <div
                                    id="card2"
                                    class="card-container mr-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('card', site_setting.backgrounds.card2)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 2, selectPlatformCard(site_setting.platforms.card2.name) ) : null, navigateToCard(platforms_temp.card2, site_setting.videos.card2 != '' ? site_setting.videos.card2 : site_setting.backgrounds.card2 ,site_setting.schedules.card2.schedule)"
                                    v-if="site_setting.backgrounds.card2 || editor || site_setting.videos.card2 || site_setting.platforms.card2.name || hasImages(site_setting.galleries.card2) || hasSchedule(site_setting.schedules.card2)"
                                >

                                    <video
                                     v-if="site_setting.videos.card2"
                                    :src="site_setting.videos.card2"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="getBase('video')"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card2"
                                    :src="site_setting.backgrounds.card2"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="getBase('card')"
                                    class="mt-0 px-0 mx-0 rounded-xl card"
                                    />

                                    <GalleryComponentCard
                                           v-if="hasImages(site_setting.galleries.card2)"
                                        :images="site_setting.galleries.card2.images"
                                        :editor="editor"
                                        class="background-media rounded-xl"
                                        galleryId="card2"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card2)"
                                    :image="site_setting.backgrounds.card2.path"
                                    :schedule="site_setting.schedules.card2.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card2"
                                    />

                                    <div v-if="site_setting.platforms.card2.show && !hasImages(site_setting.galleries.card2) && !hasSchedule(site_setting.schedules.card2)">
                                        <div v-if="!site_setting.platforms.card2.name.includes('/')"
                                        :class="( site_setting.platforms.card2.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card2.name }}</h1>
                                        </div>

                                        <div v-else>
                                            <img
                                            v-if="site_setting.platforms.card2.name"
                                            :src="backend_url+`/platforms/${site_setting.platforms.card2.name}`"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card2 && !hasImages(site_setting.galleries.card2) && !hasSchedule(site_setting.schedules.card2)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card3 | Video -->
                                <div
                                    id="card3"
                                    class="video-container mr-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('video', site_setting.backgrounds.card3)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 3, selectPlatformCard(site_setting.platforms.card3.name) ) : null, navigateToCard(platforms_temp.card3, site_setting.videos.card3 != '' ? site_setting.videos.card3 : site_setting.backgrounds.card3, site_setting.schedules.card3.schedule)"
                                    v-if="site_setting.backgrounds.card3 || editor || site_setting.videos.card3 || site_setting.platforms.card3.name || hasImages(site_setting.galleries.card3) || hasSchedule(site_setting.schedules.card3)"
                                >


                                    <video
                                     v-if="site_setting.videos.card3"
                                    :src="site_setting.videos.card3"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="getBase('video')"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card3"
                                    :src="site_setting.backgrounds.card3"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="getBase('video')"
                                    class="mt-0 px-0 mx-0 rounded-xl video"
                                    />


                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card3)"
                                        :images="site_setting.galleries.card3.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        galleryId="card3"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card3)"
                                    :image="site_setting.backgrounds.card3.path"
                                    :schedule="site_setting.schedules.card3.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card3"
                                    />

                                    <div v-if="site_setting.platforms.card3.show && !hasImages(site_setting.galleries.card3) && !hasSchedule(site_setting.schedules.card3)">
                                        <div v-if="!site_setting.platforms.card3.name.includes('/')"
                                        :class="( site_setting.platforms.card3.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card3.name }}</h1>
                                        </div>

                                        <div v-else>
                                            <img
                                            :src="backend_url + `/platforms/${site_setting.platforms.card3.name}`"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card3 && !hasImages(site_setting.galleries.card3) && !hasSchedule(site_setting.schedules.card3)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card4 -->
                                <div
                                     id="card4"
                                    class="card-container mr-2 rounded-xl card-shadow mb-4"
                                    :class="getBase2('card', site_setting.backgrounds.card4)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 4, selectPlatformCard(site_setting.platforms.card4.name) ) : null, navigateToCard(platforms_temp.card4, site_setting.videos.card4 != '' ? site_setting.videos.card4 : site_setting.backgrounds.card4 ,site_setting.schedules.card4.schedule)"
                                    v-if="site_setting.backgrounds.card4 || editor || site_setting.videos.card4 || site_setting.platforms.card4.name ||hasImages(site_setting.galleries.card4) || hasSchedule(site_setting.schedules.card4)"
                                >

                                    <video
                                     v-if="site_setting.videos.card4"
                                    :src="site_setting.videos.card4"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl"
                                    :class="getBase('video')"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>


                                    <v-img
                                    v-if="site_setting.backgrounds.card4"
                                    :src="site_setting.backgrounds.card4"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="getBase('card')"
                                    class="mt-0 px-0 mx-0 rounded-xl card"
                                    />


                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card4)"
                                        :images="site_setting.galleries.card4.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        galleryId="card4"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card4)"
                                    :image="site_setting.backgrounds.card4.path"
                                    :schedule="site_setting.schedules.card4.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card4"
                                    />

                                    <div v-if="site_setting.platforms.card4.show && !hasImages(site_setting.galleries.card4) && !hasSchedule(site_setting.schedules.card4)">
                                        <div v-if="!site_setting.platforms.card4.name.includes('/')"
                                        :class="(site_setting.platforms.card4.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card4.name }}</h1>
                                        </div>

                                        <div v-else>
                                            <img
                                            v-if="site_setting.platforms.card4.name"
                                            :src="backend_url+`/platforms/${site_setting.platforms.card4.name}`"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>


                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card4  &&  !hasImages(site_setting.galleries.card4)  && !hasSchedule(site_setting.schedules.card4)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>




                                <!--  cards añadidas por el usuario  -->

                                <template v-for="card in filteredCardsLeft"  >


                                    <div
                                    :key="card.key"
                                    :id="card.key"
                                    class="mr-2 mb-4 rounded-xl card-shadow"
                                    :class="[getBase2(getClass(site_setting.platforms[card.key].size), site_setting.backgrounds[card.key]), getSizeCard(site_setting.platforms[card.key].size), site_setting.platforms[card.key].size === 'large' ? 'video-container' : 'card-container']"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = card.number, selectPlatformCard(site_setting.platforms[card.key].name) ) : null, navigateToCard(site_setting.platforms[card.key], site_setting.videos[card.key] != '' ? site_setting.videos[card.key] : site_setting.backgrounds[card.key], site_setting.schedules[card.key].schedule)"
                                    v-if="site_setting.backgrounds[card.key] || editor || site_setting.videos[card.key] || site_setting.platforms[card.key].name || hasSchedule(site_setting.schedules[card.key]) || hasImages(site_setting.galleries[card.key]) && site_setting.platforms[card.key].position === 'left'"
                                >

                                    <video
                                     v-if="site_setting.videos[card.key]"
                                    :src="site_setting.videos[card.key]"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="getBase('video')"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds[card.key]"
                                    :src="site_setting.backgrounds[card.key]"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('card'), site_setting.platforms[card.key].size === 'large' ? 'video' : 'card']"
                                    class="mt-0 px-0 mx-0 rounded-xl "
                                    />

                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries[card.key])"
                                        :images="site_setting.galleries[card.key].images"
                                        :editor="editor"
                                        class="background-media rounded-xl"
                                        :galleryId="card.key"
                                    />
                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules[card.key])"
                                    :image="site_setting.backgrounds[card.key].path"
                                    :schedule="site_setting.schedules[card.key].schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    :scheduleId="[card.key]"
                                    />

                                    <div v-if="site_setting.platforms[card.key].show && !hasImages(site_setting.galleries[card.key]) && !hasSchedule(site_setting.schedules[card.key])">
                                        <div v-if="!site_setting.platforms[card.key].name.includes('/')"
                                        :class="( site_setting.platforms[card.key].color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms[card.key].name }}</h1>
                                        </div>

                                        <div v-else>
                                            <img
                                            v-if="site_setting.platforms[card.key].name"
                                            :src="backend_url+`/platforms/${site_setting.platforms[card.key].name}`"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds[card.key] && !hasImages(site_setting.galleries[card.key]) && !hasSchedule(site_setting.schedules[card.key])">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                </template>




                                <div>
                                    <div
                                    class="card-container mr-2 rounded-xxl card-shadow d-flex justify-center align-center"
                                    :class="getBase2('card')"
                                    @click="editor ? (menu_editor = true, select = 'add-card', position = 'left') : null"
                                    v-if="editor"
                                >
                                    <v-icon center color="#C9C9C9" size="80px">
                                        mdi-plus
                                    </v-icon>
                                </div>
                            </div>
                            </div>

                            <!-- Right section -->
                            <div id="right-section" class="sections mb-16">

                                <!-- Card5 | Video -->
                                <div
                                    id="card5"
                                    class="video-container ml-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('video', site_setting.backgrounds.card5)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 5, selectPlatformCard(site_setting.platforms.card5.name) ) : null, navigateToCard(platforms_temp.card5, site_setting.videos.card5 != '' ? site_setting.videos.card5 : site_setting.backgrounds.card5 ,site_setting.schedules.card5.schedule)"
                                    v-if="site_setting.backgrounds.card5 || editor || site_setting.videos.card5 || site_setting.platforms.card5.name || hasImages(site_setting.galleries.card5) || hasSchedule(site_setting.schedules.card5)"
                                >


                                    <video
                                     v-if="site_setting.videos.card5"
                                    :src="site_setting.videos.card5"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="getBase('video')"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>


                                    <v-img
                                    v-if="site_setting.backgrounds.card5"
                                    :src="site_setting.backgrounds.card5"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="getBase('video')"
                                    class="mt-0 px-0 mx-0 rounded-xl video"
                                    />


                                    <GalleryComponentCard
                                         v-if="hasImages(site_setting.galleries.card5)"
                                       :images="site_setting.galleries.card5.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        galleryId="card5"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card5)"
                                    :image="site_setting.backgrounds.card5.path"
                                    :schedule="site_setting.schedules.card5.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card5"
                                    />

                                    <div v-if="site_setting.platforms.card5.show && !hasImages(site_setting.galleries.card5) && !hasSchedule(site_setting.schedules.card5)">
                                        <div v-if="!site_setting.platforms.card5.name.includes('/')"
                                        :class="( site_setting.platforms.card5.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card5.name }}</h1>
                                        </div>

                                        <div v-else>
                                            <img
                                            :src="backend_url + `/platforms/${site_setting.platforms.card5.name}`"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card5  &&  !hasImages(site_setting.galleries.card5)  && !hasSchedule(site_setting.schedules.card5)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card6 -->
                                <div
                                    id="card6"
                                    class="card2-container ml-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('card2', site_setting.backgrounds.card6)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 6, selectPlatformCard(site_setting.platforms.card6.name) ) : null, navigateToCard(platforms_temp.card6, site_setting.videos.card6 != '' ? site_setting.videos.card6 : site_setting.backgrounds.card6 , site_setting.schedules.card6.schedule)"
                                    v-if="site_setting.backgrounds.card6 || editor || site_setting.videos.card6 || site_setting.platforms.card6.name || hasImages(site_setting.galleries.card6) || hasSchedule(site_setting.schedules.card6)"
                                >

                                    <video
                                     v-if="site_setting.videos.card6"
                                    :src="site_setting.videos.card6"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="getBase('video')"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card6"
                                    :src="site_setting.backgrounds.card6"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="getBase('card2')"
                                    class="mt-0 px-0 mx-0 rounded-xl card2"
                                    />


                                    <GalleryComponentCard
                                           v-if="hasImages(site_setting.galleries.card6)"
                                        :images="site_setting.galleries.card6.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        galleryId="card6"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card6)"
                                    :image="site_setting.backgrounds.card6.path"
                                    :schedule="site_setting.schedules.card6.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card6"
                                    />
                                    
                                    <div v-if="site_setting.platforms.card6.show && !hasImages(site_setting.galleries.card6) && !hasSchedule(site_setting.schedules.card6)">
                                        <div v-if="!site_setting.platforms.card6.name.includes('/')"
                                        :class="(site_setting.platforms.card6.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card6.name }}</h1>
                                        </div>

                                        <div v-else>
                                            <img
                                            v-if="site_setting.platforms.card6.name"
                                            :src="backend_url+`/platforms/${site_setting.platforms.card6.name}`"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card6  &&  !hasImages(site_setting.galleries.card6)  && !hasSchedule(site_setting.schedules.card6)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card7 -->
                                <div
                                    id="card7"
                                    class="card-container ml-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('card', site_setting.backgrounds.card7)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 7, selectPlatformCard(site_setting.platforms.card7.name) ) : null, navigateToCard(platforms_temp.card7, site_setting.videos.card7 != '' ? site_setting.videos.card7 : site_setting.backgrounds.card7 ,site_setting.schedules.card7.schedule)"
                                    v-if="site_setting.backgrounds.card7 || editor || site_setting.videos.card7 || site_setting.platforms.card7.name  || hasImages(site_setting.galleries.card7) || hasSchedule(site_setting.schedules.card7)"
                                >

                                    <video
                                     v-if="site_setting.videos.card7"
                                    :src="site_setting.videos.card7"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="getBase('video')"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card7"
                                    :src="site_setting.backgrounds.card7"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="getBase('card')"
                                    class="mt-0 px-0 mx-0 rounded-xl card"
                                    />


                                    <GalleryComponentCard
                                         v-if="hasImages(site_setting.galleries.card7)"
                                        :images="site_setting.galleries.card1.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        galleryId="card7"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card7)"
                                    :image="site_setting.backgrounds.card7.path"
                                    :schedule="site_setting.schedules.card7.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card7"
                                    />

                                    <div v-if="site_setting.platforms.card7.show && !hasImages(site_setting.galleries.card7) && !hasSchedule(site_setting.schedules.card7)">
                                        <div v-if="!site_setting.platforms.card7.name.includes('/')"
                                        :class="(site_setting.platforms.card7.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card7.name }}</h1>
                                        </div>

                                        <div v-else>
                                              <img
                                                v-if="site_setting.platforms.card7.name"
                                                :src="backend_url+`/platforms/${site_setting.platforms.card7.name}`"
                                                class="platform-logo"
                                                />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card7  &&  !hasImages(site_setting.galleries.card7)  && !hasSchedule(site_setting.schedules.card7)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card8 -->
                                <div
                                    id="card8"
                                    class="card-container ml-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('card', site_setting.backgrounds.card8)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 8, selectPlatformCard(site_setting.platforms.card8.name) ) : null, navigateToCard(platforms_temp.card8, site_setting.videos.card8 != '' ? site_setting.videos.card8 : site_setting.backgrounds.card8 ,site_setting.schedules.card8.schedule)"
                                    v-if="site_setting.backgrounds.card8 || editor || site_setting.videos.card8 || site_setting.platforms.card8.name || hasImages(site_setting.galleries.card8) || hasSchedule(site_setting.schedules.card8)"
                                >

                                    <video
                                     v-if="site_setting.videos.card8"
                                    :src="site_setting.videos.card8"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="getBase('video')"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>


                                    <v-img
                                    v-if="site_setting.backgrounds.card8"
                                    :src="site_setting.backgrounds.card8"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="getBase('card')"
                                    class="mt-0 px-0 mx-0 rounded-xl card"
                                    />


                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card8)"
                                       :images="site_setting.galleries.card8.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        galleryId="card8"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card8)"
                                    :image="site_setting.backgrounds.card8.path"
                                    :schedule="site_setting.schedules.card8.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card8"
                                    />

                                        <div v-if="site_setting.platforms.card8.show && !hasImages(site_setting.galleries.card8) && !hasSchedule(site_setting.schedules.card8)">
                                            <div v-if="!site_setting.platforms.card8.name.includes('/')"
                                            :class="(site_setting.platforms.card8.color === 1) ? 'white' : 'black'">
                                            <h1 class="platform-text">{{ site_setting.platforms.card8.name }}</h1>
                                            </div>

                                            <div v-else>
                                                <img
                                                v-if="site_setting.platforms.card8.name"
                                                :src="backend_url+`/platforms/${site_setting.platforms.card8.name}`"
                                                class="platform-logo"
                                                />
                                            </div>
                                        </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card8  && !hasImages(site_setting.galleries.card8) && !hasSchedule(site_setting.schedules.card8)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card9 -->
                                <div
                                    id="card9"
                                    class="card2-container ml-2 mt-0 rounded-xl card-shadow mb-4"
                                    :class="getBase2('card2', site_setting.backgrounds.card9)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 9, selectPlatformCard(site_setting.platforms.card9.name) ) : null, navigateToCard(platforms_temp.card9, site_setting.videos.card9 != '' ? site_setting.videos.card9 : site_setting.backgrounds.card9 ,site_setting.schedules.card9.schedule)"
                                    v-if="site_setting.backgrounds.card9 || editor || site_setting.videos.card9 || site_setting.platforms.card9.name || hasImages(site_setting.galleries.card9) || hasSchedule(site_setting.schedules.Card9)"
                                >


                                        <video
                                        v-if="site_setting.videos.card9"
                                        :src="site_setting.videos.card9"
                                        class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                        :class="getBase('video')"
                                        autoplay
                                        muted
                                        loop
                                        playsinline
                                        preload="auto"
                                        controls="false"
                                        >
                                        </video>


                                        <v-img
                                        v-if="site_setting.backgrounds.card9"
                                        :src="site_setting.backgrounds.card9"
                                        max-width="100%"
                                        max-height="100%"
                                        :class="getBase('card2')"
                                        class="mt-0 px-0 mx-0 rounded-xl card2"
                                        />

                                        <GalleryComponentCard
                                           v-if="hasImages(site_setting.galleries.card9)"
                                            :images="site_setting.galleries.card9.images"
                                            class="background-media rounded-xl"
                                            :editor="editor"
                                            galleryId="card9"
                                        />

                                        <ScheduleComponentCard
                                        v-if="hasSchedule(site_setting.schedules.card9)"
                                        :image="site_setting.backgrounds.card9.path"
                                        :schedule="site_setting.schedules.card9.schedule"
                                        class="background-media rounded-xl"
                                        :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                        scheduleId="card9"
                                        />

                                            <div v-if="site_setting.platforms.card9.show && !hasImages(site_setting.galleries.card9) && !hasSchedule(site_setting.schedules.card9)">
                                                <div v-if="!site_setting.platforms.card9.name.includes('/')"
                                                :class="(site_setting.platforms.card9.color === 1) ? 'white' : 'black'">
                                                <h1 class="platform-text">{{ site_setting.platforms.card9.name }}</h1>
                                                </div>

                                                <div v-else>
                                                    <img
                                                    v-if="site_setting.platforms.card9.name"
                                                    :src="backend_url+`/platforms/${site_setting.platforms.card9.name}`"
                                                    class="platform-logo"
                                                    />
                                                </div>
                                            </div>


                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card9  && !hasImages(site_setting.galleries.card9) && !hasSchedule(site_setting.schedules.card9)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>


                                <template v-for="card in filteredCardsRight"  >

                                <div
                                :key="card.key"
                                :id="card.key"
                                class="ml-2  mb-4 rounded-xl card-shadow"
                                :class="[getBase2(getClass(site_setting.platforms[card.key].size), site_setting.backgrounds[card.key]), getSizeCard(site_setting.platforms[card.key].size)]"
                                @click="editor ? (menu_editor = true, select = 'card', cardSelect = card.number, selectPlatformCard(site_setting.platforms[card.key].name) ) : null, navigateToCard(site_setting.platforms[card.key], site_setting.videos[card.key] != '' ? site_setting.videos[card.key] : site_setting.backgrounds[card.key] ,site_setting.schedules[card.key].schedule)"
                                v-if="site_setting.backgrounds[card.key] || editor || site_setting.videos[card.key] || site_setting.platforms[card.key].name || hasSchedule(site_setting.schedules[card.key]) || hasImages(site_setting.galleries[card.key]) && site_setting.platforms[card.key].position === 'right'"
                                >

                                <video
                                v-if="site_setting.videos[card.key]"
                                :src="site_setting.videos[card.key]"
                                class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                :class="getBase('video')"
                                autoplay
                                muted
                                loop
                                playsinline
                                preload="auto"
                                controls="false"
                                >
                                </video>

                                <v-img
                                v-if="site_setting.backgrounds[card.key]"
                                :src="site_setting.backgrounds[card.key]"
                                max-width="100%"
                                max-height="100%"
                                :class="[getBase('card'), site_setting.platforms[card.key].size === 'large' ? 'video' : 'card']"
                                class="mt-0 px-0 mx-0 rounded-xl"
                                />

                                <GalleryComponentCard
                                    v-if="hasImages(site_setting.galleries[card.key])"
                                    :images="site_setting.galleries[card.key].images"
                                    :editor="editor"
                                    class="background-media rounded-xl"
                                    :galleryId="card.key"
                                />

                                <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules[card.key])"
                                    :image="site_setting.backgrounds[card.key].path"
                                    :schedule="site_setting.schedules[card.key].schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    :scheduleId="[card.key]"
                                    />

                                <div v-if="site_setting.platforms[card.key].show && !hasImages(site_setting.galleries[card.key]) && !hasSchedule(site_setting.schedules[card.key])">
                                    <div v-if="!site_setting.platforms[card.key].name.includes('/')"
                                    :class="( site_setting.platforms[card.key].color === 1) ? 'white' : 'black'">
                                    <h1 class="platform-text">{{ site_setting.platforms[card.key].name }}</h1>
                                    </div>

                                    <div v-else>
                                        <img
                                        v-if="site_setting.platforms[card.key].name"
                                        :src="backend_url+`/platforms/${site_setting.platforms[card.key].name}`"
                                        class="platform-logo"
                                        />
                                    </div>
                                </div>

                                <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds[card.key] && !hasImages(site_setting.galleries[card.key]) && !hasSchedule(site_setting.schedules[card.key])">
                                    mdi-pencil-box
                                </v-icon>
                                </div>

                                </template>


                                <div
                                    class="card-container ml-2 mb-4 rounded-xxl card-shadow"
                                    :class="getBase2('card')"
                                    @click="editor ? (menu_editor = true, select = 'add-card', position = 'right') : null"
                                    v-if="editor"
                                >
                                    <v-icon center color="#C9C9C9" size="80px">
                                        mdi-plus
                                    </v-icon>
                                </div>


                            </div>
                            </v-row>
                        </v-row>
                        </v-col>
                        <ImageModal
                        :mediaSrc="selectedMediaSrc"
                        :mediaType="selectedMediaType"
                        :isOpen.sync="isModalOpen"
                        />
                    </v-row>
                </v-container>
                <v-btn
                    class="mb-4 button-footer "
                    rounded
                    color="black"
                    text
                    x-medium>
                    <a href="https://harmony.fan" target="_blank" class="a-footer"  v-html="$t('harmony.profile-created')">
                    </a>
                </v-btn>
            </div>
            <v-row>
            <div class="views" v-if="this.visits >=1000 && !editor">
                <v-chip
                color="purple lighten-5"
                text-color="purple darken-2"
                small
            >
                <v-icon
                left
                small
                color="purple darken-2"
                >
                mdi-eye
                </v-icon>
                {{  this.visits.toLocaleString('es-CO')}}
                </v-chip>

        </div>

        <v-tooltip right color="#8e84c0" v-if="!editor">
                 <template v-slot:activator="{ on, attrs }">
                    <div class="harmony-button" v-bind="attrs" v-on="on" @click="openHome" >
                        <img src="../assets/logo/1-10.png">
                      </div>
                </template>
                <span>¡Crea tu harmony!</span>
            </v-tooltip>
        </v-row>
        </div>
    </div>
</template>


<script>
import axios from '@/axios.js';
import ImageUpload from '@/components/partials/ImageUpload.vue';
import VideoUpload from '@/components/partials/VideoUpload.vue';
import ImageModal from '@/components/partials/ImageModal.vue';
import ImageUploadGallery from '@/components/partials/ImageUploadGallery.vue';
import GalleryComponentCard from '@/components/partials/GalleryComponentCard.vue';
import EmojiPicker from 'vue-emoji-picker'
import ScheduleComponentCard from '@/components/partials/ScheduleComponentCard.vue';
import ScheduleUpload from '@/components/partials/ScheduleUpload.vue';

export default {
    name: 'Harmony',
    components: {
        ImageUpload,
        VideoUpload,
        ImageModal,
        ImageUploadGallery,
        GalleryComponentCard,
        EmojiPicker,
        ScheduleComponentCard,
        ScheduleUpload
    },
    data() {
        return {
            harmony: {},
            editor: false,
            menu_editor: false,
            user: null,
            select: '',
            loading: true,
            settings: null,
            colorPlatform: 1,
            cardSelect: 0,
            selectBackground: 1,
            selectSocial: 'instagram',
            backend_url: 'https://backend.harmony.fan/storage',
            formattedDescription: '',
            site_setting: {
                profile: {
                    title_color: '',
                    desc_color: '',
                    description: ''
                },
                page: {
                    color: '',
                    background: '',
                    pattern: ''
                },
                style_socials: 1,
                backgrounds: {
                    card1: '',
                    card2: '',
                    card3: '',
                    card4: '',
                    card5: '',
                    card6: '',
                    card7: '',
                    card8: '',
                    card9: ''
                },
                galleries: {
                    card1: { id: null, images: [] },
                    card2: { id: null, images: [] },
                    card3: { id: null, images: [] },
                    card4: { id: null, images: [] },
                    card5: { id: null, images: [] },
                    card6: { id: null, images: [] },
                    card7: { id: null, images: [] },
                    card8: { id: null, images: [] },
                    card9: { id: null, images: [] }
                },
                platforms: {
                    card1: { name: '', nick: '', color: 1, url: '', url2: '', show: true },
                    card2: { name: '', nick: '', color: 1, url: '', url2: '', show: true },
                    card3: { name: '', nick: '', color: 1, url: '', url2: '', show: true },
                    card4: { name: '', nick: '', color: 1, url: '', url2: '', show: true },
                    card5: { name: '', nick: '', color: 1, url: '', url2: '', show: true },
                    card6: { name: '', nick: '', color: 1, url: '', url2: '', show: true },
                    card7: { name: '', nick: '', color: 1, url: '', url2: '', show: true },
                    card8: { name: '', nick: '', color: 1, url: '', url2: '', show: true },
                    card9: { name: '', nick: '', color: 1, url: '', url2: '', show: true },
                },
                videos:{
                    card1: '',
                    card2: '',
                    card3: '',
                    card4: '',
                    card5: '',
                    card6: '',
                    card7: '',
                    card8: '',
                    card9: ''
                },
                newplatforms: [],
                schedules: {
                    card1: { numSection: '', schedule: []},
                    card2: { numSection: '', schedule: []},
                    card3: { numSection: '', schedule: []},
                    card4: { numSection: '', schedule: []},
                    card5: { numSection: '', schedule: []},
                    card6: { numSection: '', schedule: []},
                    card7: { numSection: '', schedule: []},
                    card8: { numSection: '', schedule: []},
                    card9: { numSection: '', schedule: []}
                },
            },
            platforms_temp: {
                card1: {name: '', nick: '', url: '', url2: '', show: true },
                card2: {name: '', nick: '', url: '', url2: '', show: true },
                card3: {name: '', nick: '', url: '', url2: '', show: true },
                card4: {name: '', nick: '', url: '', url2: '', show: true },
                card5: {name: '', nick: '', url: '', url2: '', show: true },
                card6: {name: '', nick: '', url: '', url2: '', show: true },
                card7: {name: '', nick: '', url: '', url2: '', show: true },
                card8: {name: '', nick: '', url: '', url2: '', show: true },
                card9: {name: '', nick: '', url: '', url2: '', show: true },
            },
            platformSearch: '',
            platforms: [],
            platformNew: {
                name: '',
                url: '',
            },
            image_or_video: 1,
            selectNewPlatform: '',
            showPlatforms: false,
            showNick: false,
            showUrl: false,
            changes: {},
            socials: [],
            list_socials: [],
            style_socials_temp: null,
            rules: {
                noUrl: value => {
                    if (!value) return true;
                    const urlPattern = /^[a-zA-Z0-9._@-]+$/;
                    return urlPattern.test(value) || this.$t('harmony.invalid-nick');
                },

                noUrlPlatform: value => {
                    if (!value) return true;
                    const urlPattern = /^[a-zA-Z0-9._@]+$/;
                    return urlPattern.test(value) || this.$t('harmony.invalid-platform');
                },

                required: value => !!value || this.$t('navbar.required'),
                url: value => {
                    if (!value) return true;
                    const urlRegex = RegExp(/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,4}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/gi);
                    return urlRegex.test(value) || this.$t('harmony.invalid-url');
                },
                endTime: value => {
                    const startTime = this.selectedStartHour;
                    if (!value || !startTime) return true;
                    const start = new Date(`1970-01-01T${startTime}:00`);
                    const end = new Date(`1970-01-01T${value}:00`);
                    if (start >= end) {
                        return this.$t('harmony.invalid-end-time');
                    }

                    return true;
                }
            },
            selectedSocial: null,
            socialsBar: ['instagram', 'facebook', 'x', 'tiktok'],
            defaultSocials: [
                {
                    name: 'instagram',
                    nick: ''
                },
                {
                    name: 'facebook',
                    nick: ''
                },
                {
                    name: 'tiktok',
                    nick: ''
                }
            ],
            newSocial: {
                name: '',
                nick: ''
            },
            dialog: false,
            showUrl2: false,
            loadingSave: false,
            isModalOpen: false,
            selectedMediaSrc: '',
            selectedMediaType: 'image',
            itemsForUpload: [],
            notShowField : false,
            galleryUpdated: false,
            position: '',
            size: 'small',
            sizes: [
                {
                    name: 'Pequeño',
                    value: 'small'
                },
                {
                    name: 'Mediano',
                    value: 'medium'
                },
                {
                    name: 'Grande',
                    value: 'large'
                }
            ],
            positions: [
                {
                    name: 'Izquierda',
                    value: 'left'
                },
                {
                    name: 'Derecha',
                    value: 'right'
                }
            ],
            saving: false,
            visits: 0,
            dialogEmoticons:false,
            input: '',
            search: '',
            selectedStartHour: null,
            selectedEndHour: null,
            name_day: null,
            urlSchedule: null,
            addSchedule: false,
            dayOptions: [],
            loadingSchedules: false
        };
    },
    metaInfo() {
        const defaultImage = 'https://backend.harmony.fan/storage/images/harmony.png';
        return {
            titleTemplate: '%s - Harmony Fan',
            title: this.harmony.name || 'Harmony Fan',
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'description', content: this.harmony.description || 'Harmony fan es una herramienta que te permite con un solo Clic conectar tu audiencia con tus plataformas y ganar dinero al hacerlo.' },
                { property: 'og:title', content: this.harmony.name || 'Harmony Fan' },
                { property: 'og:description', content: this.harmony.description || 'Harmony fan es una herramienta que te permite con un solo Clic conectar tu audiencia con tus plataformas y ganar dinero al hacerlo.' },
                { property: 'og:image', content: this.harmony.logo_path || defaultImage },
                { property: 'og:url', content: `https://harmony.fan/${this.harmony.slug}` },
                { name: 'twitter:title', content: this.harmony.name || 'Harmony Fan' },
                { name: 'twitter:description', content: this.harmony.description || 'Harmony fan es una herramienta que te permite con un solo Clic conectar tu audiencia con tus plataformas y ganar dinero al hacerlo.' },
                { name: 'twitter:image', content: this.harmony.logo_path || defaultImage },
                { name: 'twitter:card', content: 'summary_large_image' }
            ],
        };
    },
    watch: {
        menu_editor(newValue) {
            if (!newValue) {
                //this.saveSettings()
                this.showNick = false;
                this.showPlatforms = false;
                this.showUrl = false;
                this.platformSearch = '';
                this.selectNewPlatform = '';
                this.showUrl2 = false;
                this.saveSettingsAuto()
            } else {
                const platform = this.site_setting.platforms[`card${this.cardSelect}`];
                if (platform && platform.name) {
                    const platformName = platform.name.split('/')[0];
                    if (platformName) {
                        this.platformSearch = platformName;
                        this.showPlatforms = true;
                    }
                }
            }
        },
        defaultImageOrVideo(newValue){
            this.image_or_video = newValue;
        },

        cardSelect(){

            this.image_or_video = this.defaultImageOrVideo;
        },

        image_or_video(newValue) {
             if (newValue === 3) {
                this.site_setting.platforms[`card${this.cardSelect}`].name = '';
                this.site_setting.platforms[`card${this.cardSelect}`].nick = '';
                this.site_setting.platforms[`card${this.cardSelect}`].url = '';
                this.site_setting.platforms[`card${this.cardSelect}`].url2 = '';
                this.saveSettingsAuto();
             }
        },

        selectedNewPlatform(newValue){
            if(newValue){
                this.showUrl2 = false;
            }
        },
        addSchedule(newValue) {
            if (!newValue) {
            // Ejecuta la función que quieras cuando addSchedule sea false
            this.onAddScheduleClosed();
            }
        }

    },
    mounted() {
    },

    computed: {
        isFormValid() {
            return (
                this.rules.required(this.name_day) === true &&
                this.rules.required(this.selectedStartHour) === true &&
                this.rules.required(this.selectedEndHour) === true &&
                this.rules.url(this.urlSchedule) === true &&
                this.rules.endTime(this.selectedEndHour) === true
            );
        },
        filteredDayOptions() {
            let occupiedDays = this.site_setting.schedules[`card${this.cardSelect}`].schedule.map(item => item.name_day);
            const filteredOptions = this.dayOptions.filter(day => {
                return !occupiedDays.includes(String(day.id));
            });
            return filteredOptions;
        },

        filteredPlatforms() {
            return this.platforms.filter(platform =>
                platform.name.toLowerCase().includes(this.platformSearch.toLowerCase())
            );
        },
        selectedCard() {
            return this.site_setting.platforms[`card${this.cardSelect}`];
        },

        selectedNewPlatform() {
            return this.site_setting.newplatforms.find(platform => platform.name === this.selectNewPlatform);
        },

        defaultImageOrVideo() {
            const hasImage = !!this.site_setting.backgrounds[`card${this.cardSelect}`];
            const hasVideo = !!this.site_setting.videos[`card${this.cardSelect}`];
            const hasGallery = this.hasImages(this.site_setting.galleries[`card${this.cardSelect}`]);
            const hasSchedule = this.hasSchedule(this.site_setting.schedules[`card${this.cardSelect}`]);
            const hasNumSection = !!(this.site_setting.schedules[`card${this.cardSelect}`]?.numSection);

            if (hasImage && !hasNumSection && !hasSchedule) {
                return 1; // Imagen
            }
            else if (hasVideo) {
                return 2; // Video
            }
            else if (hasGallery) {
                return 3; // Galería
            }
            else if (hasSchedule || hasNumSection) {
                return 4; // Horarios o Sección numerada
            }
            return 1; // Imagen por defecto
        },


        isDisabled() {
            const cardKey = `card${this.cardSelect}`;
            const schedule = this.site_setting.schedules[cardKey];
            const hasSchedule = this.hasSchedule(schedule);
            const hasNumSection = !!(schedule?.numSection);

            if (this.image_or_video === 4) {
                return hasSchedule || hasNumSection;
            } else if (this.image_or_video === 2) {
                return !!this.site_setting.videos[cardKey];
            } else if (this.image_or_video === 3) {
                return this.hasImages(this.site_setting.galleries[cardKey]);
            } else if (this.image_or_video === 1) {
                return !!this.site_setting.backgrounds[cardKey];
            }
            return false;
        },

        getHeight() {
        const heightConfig = {
            card: {
                allVisible:  window.innerHeight > 830 ? '75vh' : '80vh',
                platformsAndNick:  window.innerHeight > 830 ? '65vh' : '71vh',
                platformsOnly:  '60vh',
                default: '350px',
                gallery: '250px',
                schedule: 'auto',
            },
            addCard: '210px',
            logo: this.harmony.logo_path ? '640px' : '480px',
            title: '330px',
            socials: {
                1: '280px',
                2: '340px',
                3: '410px',
                4:  window.innerHeight > 830 ? '55vh' : '80vh',
                5: '550px',
                6: '620px',
                7: '680px',
                8: '750px',
                9: '820px',
                10: '890px'
            },
            description: '500px',
            default: '500px'
        };
        if (this.select === 'add-card'){
            return heightConfig.addCard;
        }

        if (this.select === 'logo') {

            if (this.selectBackground == 1){
                if (this.harmony.logo_path){
                    return parseInt(heightConfig.logo) + 50 + 'px';
                }else {
                    return parseInt(heightConfig.logo) + 170 + 'px';
                }
            }else {
                if (this.harmony.logo_path){
                    return heightConfig.logo;
                }else{
                    return parseInt(heightConfig.logo) + 130 + 'px';
                }
            }
        }

        if (this.select === 'card') {
            if(this.image_or_video === 3){
                return heightConfig.card.gallery;
            }
            if(this.image_or_video === 4){
                return heightConfig.card.schedule;
            }
            if (this.showUrl || this.showUrl2) {
                return heightConfig.card.allVisible;
            }
            if (this.showNick) {
                return heightConfig.card.platformsAndNick;
            }
            if (this.showPlatforms) {
                return heightConfig.card.platformsOnly;
            }
            return heightConfig.card.default;
        }

        if (this.select === 'title') {
            return heightConfig.title;
        }

        if (this.select === 'socials') {
            const count = this.countSocials();

            if (count === 0) {
                return heightConfig.socials[3];
            }

            if (count >= 1 && count <= 10) {
                return heightConfig.socials[count];
            }
        }

        if (this.select === 'description') {
            return heightConfig.description;
        }

        return heightConfig.default;
       },
       maxHeight() {
        const heightConfig = {
            card: {
                allVisible: 675,
                platformsAndNick: 600,
                platformsOnly: 510,
                default: 350,
                schedule: 500
            },
            logo: this.harmony.logo_path ? 640 : 480,
            title: 330,
            socials: {
                1: 280,
                2: 340,
                3: 410,
                4: 480,
                5: 550,
                6: 620,
                7: 680,
                8: 750,
                9: 820,
                10: 890
            },
            description: 500,
            default: 500,
        };

        if (this.select === 'logo') {
            if (this.selectBackground == 1) {
                if (this.harmony.logo_path) {
                    return (heightConfig.logo + 50) + 'px';
                } else {
                    return (heightConfig.logo + 170) + 'px';
                }
            } else {
                if (this.harmony.logo_path) {
                    return heightConfig.logo + 'px';
                } else {
                    return (heightConfig.logo + 130) + 'px';
                }
            }
        }

        if (this.select === 'card') {
            if(this.image_or_video === 4){
                return heightConfig.card.schedule;
            }
            if (this.showUrl || this.showUrl2) {
                return heightConfig.card.allVisible + 'px';
            }
            if (this.showNick) {
                return heightConfig.card.platformsAndNick + 'px';
            }
            if (this.showPlatforms) {
                return heightConfig.card.platformsOnly + 'px';
            }
            return heightConfig.card.default + 'px';
        }

        if (this.select === 'title') {
            return heightConfig.title + 'px';
        }

        if (this.select === 'socials') {
            const count = this.countSocials();
            if (count === 0) {
                return heightConfig.socials[4] + 'px';
            }
            if (count >= 1 && count <= 10) {
                return heightConfig.socials[count] + 'px';
            }
            return heightConfig.socials[10] + 'px';
        }

        if (this.select === 'description') {
            return heightConfig.description + 'px';
        }

        return heightConfig.default + 'px';
    },


        valid() {
            if (this.selectedCard === undefined) {
                return false;
            }
            return this.rules.noUrl(this.selectedCard.nick) === true;
        },
        validPlatform() {
            return this.rules.noUrlPlatform(this.platformSearch) === true;
        },

        validNick() {
            return this.rules.required(this.newSocial.nick) === true;
        },

        socialItems() {
            return this.countSocials() > 0 ? this.socials : this.defaultSocials;
        },
        buttonContainerStyle() {
            return {
                height: (!this.showUrl2 && !this.showUrl && !this.showNick && this.showPlatforms) ? '90px' : '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            };
        },

        showPlatformCard:{
            get(){
                return this.site_setting.platforms[`card${this.cardSelect}`].show;
            },
            set(value){
                this.site_setting.platforms[`card${this.cardSelect}`].show = value;
            }
        },
        filteredCardsRight() {
        return Object.entries(this.site_setting.platforms)
            .filter(([key, value]) => {
            const cardNumber = parseInt(key.replace('card', ''));
            return cardNumber >= 10 && value.position === 'right';
            })
            .map(([key, value]) => ({ key, value, number: parseInt(key.replace('card', '')) }));
        },
        filteredCardsLeft() {
         return Object.entries(this.site_setting.platforms)
            .filter(([key, value]) => {
            const cardNumber = parseInt(key.replace('card', ''));
            return cardNumber >= 10 && value.position === 'left';
            })
            .map(([key, value]) => ({ key, value, number: parseInt(key.replace('card', '')) }));
        }
    },

    async created() {
        if (this.$route.path.includes('/editor')) {
            await this.getPlatforms()
        }

        const url = window.location.href;
        const slugMatch = url.match(/^https?:\/\/[^/]+\/([^/?#]+)/);
        const preSlug = slugMatch ? slugMatch[1] : null;
        const slug = preSlug ? preSlug : this.$route.params.slug;

        await this.loadHarmony(slug);

        // Check if the route includes 'editor' and if the user has permission
        if (this.$route.path.includes('/editor')) {
            await this.checkEditorPermission(slug);
        }

        this.checkUser()
        this.loading = false;

        this.setLanguage()
        this.setDayOptions()
        this.setSelectList()
    },

    methods: {
        setSelectList (){
            this.itemsForUpload = [
                {
                    name: this.$t('harmony.image'),
                    id: 1,
                },
                {
                    name: this.$t('harmony.video'),
                    id: 2,
                },
                {
                    name: this.$t('harmony.gallery'),
                    id: 3,
                },
                {
                    name: this.$t('harmony.schedule'),
                    id: 4,
                }
            ]
        },
        addLineBreaks(text) {
            if (!text) return '';
            return text.replace(/\n/g, '<br>');
        },

        append(emoji) {
            this.site_setting.profile.description += emoji
        },

        handleDescriptionInput() {
            this.formattedDescription = this.addLineBreaks(this.site_setting.profile.description);
            console.log(this.formattedDescription)
        },

        getSocialPlaceholder(value){
            switch (true) {
                case value == 'whatsapp':
                    return this.$t('harmony.whatsapp-label')
                case value == 'email':
                    return this.$t('harmony.mail-label')
                default:
                    return this.$t('harmony.enter-username')
            }
        },

        openHome(){
            window.open('/', '_blank')
        },

        getIconSize() {
            const count = this.countSocials();
            if (count <= 2) {
                return 'icon-size-large';
            } else if (count <= 3) {
                return 'icon-size-medium';
            } else if (count <= 4) {
                return 'icon-size-small';
            } else {
                return 'icon-size-smallest';
            }
        },

        setLanguage(){
            const storedLocale = localStorage.getItem('locale');
            if (storedLocale) {
                this.$i18n.locale = storedLocale;
            } else {
                this.$i18n.locale = 'es'
            }
        },

        checkPageColor (){
            if (this.site_setting.page.color == '#D4D4D4' && !this.site_setting.page.background || this.site_setting.page.color == '#FFFFFF' && !this.site_setting.page.background){
                return false
            }else {
                return true
            }
        },

        handleInput(key) {
            this.changes[key] = true;
        },

        async saveSocial(item) {
            this.loadingSave = true;
            const response = await axios.post('save-social', { social: item, site_id: this.harmony.id })
            const slug = this.$route.params.slug;
            await this.loadHarmony(slug);
            if (Object.prototype.hasOwnProperty.call(this.changes, item.name)) {
                this.changes[item.name] = false;
            }
            if (response.data) {
                this.dialog = false
                this.$vs.notify({
                    title: this.$t('harmony.social-saved-title'),
                    text: this.$t('harmony.social-saved-text'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                })
            }
            this.loadingSave = false;

        },


        async deleteSocial(item){
            this.loadingSave = true;
            const response = await axios.post('remove-social', {social: item, site_id: this.harmony.id})
            const slug = this.$route.params.slug;
            await this.loadHarmony(slug);
            if (response.data){
                this.dialog = false
                this.$vs.notify({
                    title: this.$t('harmony.social-deleted-title'),
                    text: this.$t('harmony.social-deleted-text'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                })
            }
            this.loadingSave = false;
        },

        async removeLogo (id) {
            try {
                const response = await axios.post('remove-logo', {site_id: id});
                if (response.data){
                    this.$vs.notify({
                        title: this.$t('harmony.social-deleted-title'),
                        text: this.$t('harmony.social-deleted-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    })
                    const slug = this.$route.params.slug;
                    await this.loadHarmony(slug);
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getSocials() {
            try {
                    const response = await axios.get('get-socials');
                    this.list_socials = response.data;
                if (this.countSocials() < 0) {
                    const socialNames = this.defaultSocials.map(social => social.name.toLowerCase());
                    this.list_socials = this.list_socials.filter(item => !socialNames.includes(item.value.toLowerCase()));
                }
            } catch (error) {
                console.log(error);
            }
        },

        async removeBackground(value){
            try {
                const response = await axios.post('remove-background', {background: value});
                if (response.data){
                    this.$vs.notify({
                        title: this.$t('harmony.social-deletedBackground-title'),
                        text: this.$t('harmony.social-deletedBackground-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                    Object.keys(this.site_setting.backgrounds).forEach(key => {
                        if (this.site_setting.backgrounds[key] === value) {
                            this.site_setting.backgrounds[key] = '';
                        }
                    });

                    if (this.site_setting.page.background == value){
                        this.site_setting.page.background = ''
                    }

                    this.saveSettings()

                }
            } catch (error) {
                console.log(error)
            }
        },

        async getPlatforms(){
            try {
                const response = await axios.get('get-platforms');
                this.platforms = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        addPlatform() {

            const newPlatform = { ...this.platformNew };
            newPlatform.name = this.platformSearch;

            const existingPlatform = this.platforms.find(platform => platform.name === newPlatform.name);

            if (!existingPlatform) {
                this.platforms.push(newPlatform);
                this.site_setting.newplatforms.push(newPlatform);
                this.selectPlatform(newPlatform.name);
            }
            this.platformNew = { name: '', nick: '', url: '', color: 1 };

        },

         getPlatformImage(platform) {
            if (this.colorPlatform === 1) {
                return platform.srcBlack;
            } else if (this.colorPlatform === 2) {
                return platform.srcWhite;
            } else {
                return platform.srcColor;
            }
        },

        setSocialStyle(value){
            this.selectedSocial = value;
            this.site_setting.style_socials = value
            this.saveSettings()
        },

        formatUrl(social, input) {
            const baseUrls = {
                instagram: "https://www.instagram.com/",
                facebook: "https://www.facebook.com/",
                x: "https://x.com/",
                tiktok: "https://www.tiktok.com/@",
                paypal: "https://www.paypal.me/",
                skype: "skype:",
                snapchat: "https://www.snapchat.com/add/",
                spotify: "https://open.spotify.com/user/",
                twitch: "https://www.twitch.tv/",
                youtube: "https://www.youtube.com/@",
                telegram: "https://t.me/",
                pinterest: "https://www.pinterest.com/",
                tinder: "https://tinder.com/@",
                whatsapp: "https://wa.me/",
                discord: "https://discord.com/",
                linkedin: "https://www.linkedin.com/in/",
                amazon: "https://www.amazon.com/",
                email: "mailto:",
                web: "https://"
            };

            const ensureHttps = (url) => {
                if (url.startsWith('http://') || url.startsWith('https://')) {
                    return url;
                }
                return 'https://' + url;
            };

            if (input.includes('.') && (input.startsWith('http') || input.startsWith('www'))) {
                return ensureHttps(input);
            }

            if (social === 'paypal') {
                if (input.includes('paypal.me') || input.includes('paypalme')) {
                    return 'https://www.paypal.me/' + input.split(/[/]/).pop().replace('@', '');
                }
            }

            if(social === 'web'){
                return ensureHttps(input);
            }

            const baseUrl = baseUrls[social] || '';

            let formattedInput;
            if (social === 'email') {
                formattedInput = input; // No quitar el arroba
            } else {
                formattedInput = input.replace('@', '');
            }

            const domainPattern = new RegExp(`^(?:https?://)?(?:www\\.)?${social}\\.com/?`, 'i');
            formattedInput = formattedInput.replace(domainPattern, '');

            return baseUrl + formattedInput.split(/[/]/).pop();
        },

        getIconPath(social) {
            return `https://backend.harmony.fan/storage/socials/${this.site_setting.style_socials}/${social}.svg`;
        },

        getSocialStyle (value){
            return this.$t('harmony.style')+value
        },

        goHarmony (value) {
            window.open(value, '_blank');
        },

        async goToURL(value) {
            if (value && !this.editor) {
                try {
                    await axios.post('/set-site-click', { site_id: this.harmony.id, url: value });
                } catch (error) {
                    console.error('Error al registrar el clic:', error);
                    await axios.post('/save-errors', {
                        site_id: this.harmony.id,
                        error: error.toString(),
                        userAgent: navigator.userAgent
                    });
                }
                window.location.href = value;

                /*const userAgent = navigator.userAgent;
                const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
                const isInstagram = /Instagram/.test(userAgent);
                const isFacebook = /FBAN|FBAV|FB_IAB/.test(userAgent);
                const isWhatsApp = /WhatsApp/.test(userAgent);

                if (isSafari || isInstagram || isFacebook || isWhatsApp) {
                    try {
                        const newWindow = window.open('', '_blank');
                        if (newWindow) {
                            newWindow.location.href = value;
                        } else {
                            // Si no se puede abrir una nueva ventana, navega en la misma pestaña
                            window.location.href = value;
                        }
                    } catch (error) {
                        console.error('Error al intentar abrir la nueva ventana:', error);
                        // Guardar el error
                        await axios.post('/save-errors', {
                            site_id: this.harmony.id,
                            error: error.toString(),
                            userAgent: navigator.userAgent
                        });
                        window.location.href = value; // Fallback a navegación en la misma pestaña
                    }
                } else {
                    try {
                        const a = document.createElement('a');
                        a.href = value;
                        a.target = '_blank';
                        a.rel = 'noopener noreferrer';
                        a.click();
                    } catch (error) {
                        console.error('Error al intentar abrir el enlace:', error);
                        // Guardar el error
                        await axios.post('/save-errors', {
                            site_id: this.harmony.id,
                            error: error.toString(),
                            userAgent: navigator.userAgent
                        });
                        window.location.href = value; // Fallback a navegación en la misma pestaña
                    }
                }

                return false;*/
            }
        },

        navigateToCard(card, src, schedule) {
            //console.log('Card:', card);
            let urlSchedule = "";
            if(schedule){
                urlSchedule = this.getUrlSchedule(schedule)
            }
            const url = card.url2 !== '' && card.url2 !== undefined  ? card.url2 : card.url;
            if (url !== '') {
                this.goToURL(url);
            }else if(!this.editor && urlSchedule){
                window.open(urlSchedule, '_blank');
            }
            else if (!this.editor) {
                //console.log('esto es src:' + src);
                const extension = src.split('.').pop().toLowerCase();
                const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
                const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];

                if (imageExtensions.includes(extension)) {
                    this.isModalOpen = true;
                    this.selectedMediaSrc = src;
                    this.selectedMediaType = 'image';

                } else if (videoExtensions.includes(extension)) {
                    this.isModalOpen = true;
                    this.selectedMediaSrc = src;
                    this.selectedMediaType = 'video';
                } else {
                    console.log('La URL no es ni una imagen ni un video.');
                }
            }
        },

        setPattern(value){
            this.site_setting.page.pattern = value
        },

        getBackgroundPattern (value){
            return {
                //backgroundImage: `url(http://127.0.0.1:8000/storage/backgrounds2/${value}?v=2)`,
                backgroundImage: `url(https://backend.harmony.fan/storage/backgrounds2/${value}?v=2)`,
                backgroundSize: '62rem',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            };
        },

        getPageBackgroundStyle() {
            if (this.site_setting.page.background) {
                return {
                    backgroundImage: `url(${this.site_setting.page.background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    /*backgroundRepeat: 'repeat',*/
                    /*position: 'relative',*/
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '0',
                };
            }
            return {};
        },

        getBackgroundStyle() {
            //let backgroundImage = "https://backend.harmony.fan/storage/backgrounds2/puntos.png?v=2";
            let backgroundImage = "";

            if (this.site_setting.page.pattern) {
                // Si existe un patrón definido, úsalo
                backgroundImage = `https://backend.harmony.fan/storage/backgrounds2/${this.site_setting.page.pattern}.png?v=5`;
              // backgroundImage = `http://127.0.0.1:8000/storage/backgrounds2/${this.site_setting.page.pattern}.png?v=5`;
            } else if (this.site_setting.page.background) {
                // Si la configuración de fondo está llena, usa la imagen de fondo
                //backgroundImage = `https://backend.harmony.fan/storage/backgrounds2/puntos.png?v=2`;
            } else {
                // Si la configuración de fondo está vacía, usa la imagen de fondo por defecto
                /*backgroundImage = "https://backend.harmony.fan/storage/backgrounds2/editor-background.png?v=2";
                if (!this.site_setting.page.color){
                }*/
                //backgroundImage = "";
            }

            return {
                backgroundImage: `url(${backgroundImage})`,
                /*height: '100vh',*/
                backgroundColor: this.site_setting.page.background ? '' : this.site_setting.page.color,
            };
        },

        getTitle (){
            if (this.editor){
                return 'cursor'
            }else {
                return ''
            }
        },

        returnPanel(){
            window.location.href = '/panel'
        },

        /*getPlatfomUrl(value){
            if (!value.name || this.editor || !value.nick){
                return false
            }else{
                return value.nick
            }
           console.log(value)
        },*/

        isValidWebUrl(url) {
            let regEx = /^(http|https)/;
            return regEx.test(url);
        },

        removePlatform (){
            this.site_setting.platforms[`card${this.cardSelect}`].name = ''
            this.site_setting.platforms[`card${this.cardSelect}`].nick = ''
            this.site_setting.platforms[`card${this.cardSelect}`].url = ''
            this.site_setting.platforms[`card${this.cardSelect}`].url2 = ''
            this.platformSearch = ''
            this.selectedNewPlatform.url = ''
            this.saveSettings()
        },

        extractParam(url, param){
            if (this.isValidWebUrl(url)){
                const regex = new RegExp(`${param}=([^&]+)`);
                const matches = url.match(regex);

                if (matches) {
                    return decodeURIComponent(matches[1]);
                } else {
                    return url
                }
            }else{
                return url
            }
        },

        getBgPlatforms(value){
            if(value == 1){
                return ''
            }else if(value == 2){
                return 'bg-platform'
            }else {
                return 'bg-platform2'
            }
        },

        getPlatformName(fullPath) {
            if (fullPath) {
                const parts = fullPath.split('/');
                return parts[0];
            }
            return '';
        },

        selectPlatform(platformName) {
            // Buscar la plataforma seleccionada en la lista de plataformas
            const selectedPlatform = this.platforms.find(platform => platform.name === platformName);
            this.selectNewPlatform = '';

            if (selectedPlatform) {
                this.showNick = true;
                let src;

                if (this.colorPlatform === 1) {
                    src = selectedPlatform.srcBlack;
                } else if (this.colorPlatform === 2) {
                    src = selectedPlatform.srcWhite;
                } else {
                    src = selectedPlatform.srcColor;
                }

                if (src) {
                    this.showUrl = false;
                    // Extraer el nombre correcto del archivo desde la URL
                    const fileName = src.split('/').pop(); // Extrae el nombre del archivo (ej. CHERRYTV1_NEGRO.png)
                    const name = selectedPlatform.name.split('/')[0].toUpperCase();

                    this.showUrl2 = ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'X', 'YOUTUBE', 'SPOTIFY', 'VIMEO', 'THREADS', 'LINKEDIN', 'PINTEREST', 'REDDIT', 'DISCORD', 'TELEGRAM', 'WHATSAPP', 'AMAZON', 'MANYVIDS'].includes(name);

                    // Guardar el nombre del archivo con la ruta y color correspondiente
                    this.site_setting.platforms[`card${this.cardSelect}`].name = `${selectedPlatform.name}/${fileName}`;
                } else {
                    this.selectNewPlatform = selectedPlatform.name;
                    this.showUrl = true;
                    this.site_setting.platforms[`card${this.cardSelect}`].name = selectedPlatform.name;
                    this.site_setting.platforms[`card${this.cardSelect}`].color = this.colorPlatform === 1 ? 2 : 1;
                }
            }
        },

        changeColor(color) {
            //console.log(color)
            this.colorPlatform = color;
            const cardKey = `card${this.cardSelect}`;
            const platformName = this.site_setting.platforms[cardKey].name.split('/')[0];
            const selectPlatform = this.platforms.find(platform => platform.name === platformName);

            if (selectPlatform) {
                if (selectPlatform.srcBlack || selectPlatform.srcWhite) {
                    let src;

                    if (this.colorPlatform === 1) {
                        src = selectPlatform.srcBlack;
                    } else if (this.colorPlatform === 2) {
                        src = selectPlatform.srcWhite;
                    } else {
                        src = selectPlatform.srcColor;
                    }

                    const parts = src.split('/');
                    const fileName = parts[parts.length - 1];
                    this.site_setting.platforms[cardKey].name = `${platformName}/${fileName}`;
                } else {
                    if (this.colorPlatform === 1) {
                        this.site_setting.platforms[`card${this.cardSelect}`].color = 2;
                    } else {
                        this.site_setting.platforms[`card${this.cardSelect}`].color = 1;
                    }
                }
            }
        },

        selectPlatformCard(platformName) {
            const selectedPlatform = this.platforms.find(platform => platform.name === platformName.split('/')[0]);
            this.selectNewPlatform = '';
            this.showNick = false;
            this.showUrl = false;

            if (selectedPlatform) {
                this.showNick = true;
                const parts = platformName.split('/');
                const afterSlash = parts[1];

                if (selectedPlatform.srcBlack || selectedPlatform.srcWhite) {
                    this.colorPlatform = afterSlash.includes('NEGRO') ? 1 : afterSlash.includes('COLOR') ? 3 : 2;
                    this.showUrl2 = ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'X', 'YOUTUBE', 'SPOTIFY', 'VIMEO', 'THREADS', 'LINKEDIN', 'PINTEREST', 'REDDIT', 'DISCORD', 'TELEGRAM', 'WHATSAPP', 'AMAZON', 'MANYVIDS'].includes(parts[0]);
                } else {
                    this.selectNewPlatform = selectedPlatform.name;
                    this.site_setting.platforms[`card${this.cardSelect}`].name = selectedPlatform.name;
                    this.showUrl = true;

                    const platformColor = this.site_setting.platforms[`card${this.cardSelect}`].color;
                    this.colorPlatform = platformColor === 2 ? 1 : platformColor === 1 ? 2 : this.colorPlatform;
                }
            }
        },

        getBase2(value, data){
            if (value == 'card'){
                if (data){
                    if (this.editor && !data){
                        return 'base card'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'card base'
                }
            }else if (value == 'video'){
                if (data){
                    if (this.editor && !data){
                        return 'base video'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'video base'
                }
            }else if (value == 'card2'){
                if (data){
                    if (this.editor && !data){
                        return 'base card2'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'card2 base'
                }
            }
        },

        getBase(value, data){
            if (value == 'socials'){

                if (data == 0){
                    return 'base base-socials-editor'
                }else{
                    if (this.editor){
                        return 'cursor socials base-socials'
                    }else{
                        return 'socials base-socials'
                    }
                }

            }else if (value == 'logo'){
                if (this.editor){
                    return 'logo'
                }else{
                    return 'logo2'
                }
            }else if (value == 'card'){
                if (data){
                    if (this.editor && !data.name){
                        return 'base card'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'base'
                }
            }else if (value == 'card'){
                if (data){
                    if (this.editor && !data.name){
                        return 'video card'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'base'
                }
            }else if (value == 'card2'){
                if (data){
                    if (this.editor && !data.name){
                        return 'card2 card'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'base'
                }
            }else{
                return 'base'
            }
        },

        async saveSettings(){
            this.loadingSave = true
            this.saving = true
            this.updateNick()

            try {
                const response = await axios.post('/set-site-setting', {site_id: this.harmony.id, setting: JSON.stringify(this.site_setting)});

                    const slug = this.$route.params.slug;
                    await this.loadHarmony(slug);

                if (response.data.inUse) {
                    this.$vs.notify({
                        title: this.$t('harmony.social-errorInuse-title'),
                        text: this.$t('harmony.social-errorInuse-text'),
                        color: 'danger',
                        icon: 'error',
                        position: 'bottom-center',
                        time: 5000,
                    })
                } else {
                    this.$vs.notify({
                        title: this.$t('harmony.social-configSaved-title'),
                        text: this.$t('harmony.social-configSaved-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    })
                }
                    //this.menu_editor = false

                    this.loadingSave = false
            } catch (error) {
                console.error(error);
                /*if (error.response.data.socials){
                }*/
            }

            this.saving = false
        },

        async saveSettingsAuto() {
        if(this.valid && !this.saving){
            this.updateNick()
            try {
                for (const social of this.socialItems) {
                    if (this.changes[social.name]) {
                        // Guarda los cambios de redes sociales
                        await axios.post('save-social', { social: social, site_id: this.harmony.id });

                        if (Object.prototype.hasOwnProperty.call(this.changes, social.name)) {
                            this.changes[social.name] = false;
                        }
                    }
                }
                await axios.post('/set-site-setting', { site_id: this.harmony.id, setting: JSON.stringify(this.site_setting) });
                const slug = this.$route.params.slug;
                await this.loadHarmony(slug);
            } catch (error) {
                console.error(error);
                /*if (error.response.data.socials){
                }*/
            }
        }
        },

        checkUser(){
            const storedUser = localStorage.getItem('user');
            this.user = storedUser ? JSON.parse(storedUser) : null;
        },

        async handleImageUploaded() {
            const slug = this.$route.params.slug;
            await this.loadHarmony(slug);
            await this.saveSettings(slug);
            this.menu_editor = true

            try {
                const response = await axios.get('/get-my-info');
                const userData = response.data;
                localStorage.setItem('user', JSON.stringify(userData));
                this.user = response.data
            } catch (error) {
                console.error('Failed to update user info:', error);
            }
            this.setDayOptions()
        },

        async setSettings(value) {
            let changesWereMade = false;
            if (!value) {
                if (this.user.id == this.harmony.user_id && this.harmony.status_id != 4) {
                    this.site_setting.profile.title_color = '#373535'
                    this.site_setting.profile.desc_color = '#373535'
                    this.site_setting.page.color = '#D4D4D4'
                    this.site_setting.page.pattern = 'puntos'
                    this.site_setting.profile.description = this.harmony.description
                    this.selectedSocial = 1
                    await this.saveSettings()
                }
                } else {

                    try {
                        this.site_setting.profile.title_color = value.profile.title_color;
                        this.site_setting.profile.desc_color = value.profile.desc_color;
                        this.site_setting.profile.description = this.harmony.description;
                        this.site_setting.page.pattern = value.page.pattern;
                        this.site_setting.page.color = value.page.color;
                        this.selectedSocial = value.style_socials;

                        const ensureProperty = (obj, prop, defaultValue) => {
                            // Verificar si la propiedad existe
                            if (obj[prop] !== undefined) {
                                return true;
                            }

                            // Si no existe, agregarla con el valor predeterminado
                            this.$set(obj, prop, defaultValue);

                            // Volver a verificar si la propiedad ahora existe
                            return obj[prop] !== undefined;
                        };


                        // Schedules
                        const schedules = this.harmony.site_settings.schedule;

                        Object.keys(this.site_setting.schedules).forEach(card => {
                            this.site_setting.schedules[card].schedule = [];
                            this.site_setting.schedules[card].numSection = '';
                        });

                        schedules.forEach(schedule => {
                            const section = schedule.section;

                            ensureProperty(this.site_setting.schedules, schedules.section, { numSection: '', schedule: [] });

                            this.site_setting.schedules[section] = {
                                ...this.site_setting.schedules[section],
                                schedule: schedules
                                    .filter(event => event.section === section)
                                    .map(event => ({
                                        id: event.id,
                                        name_day: event.name_day,
                                        start_time: event.start_time,
                                        end_time: event.end_time,
                                        url: event.url
                                    }))
                            };
                        });

                        // Backgrounds
                        const backgrounds = this.harmony.site_settings.backgrounds;
                        backgrounds.forEach(background => {
                            if (background.section === 'page-background') {
                                this.site_setting.page.background = background.path;
                            } else {
                                const section = background.section.split('/')[0];
                                ensureProperty(this.site_setting.backgrounds, background.section, '');
                                this.site_setting.backgrounds[section] = background.path;
                                if(background.section.split('/')[1]){
                                    const numberSection = background.section.split('/')[1];
                                    ensureProperty(this.site_setting.schedules, section, { numSection: '', schedule: [] });
                                    this.site_setting.schedules[section].numSection = numberSection;
                                }
                            }
                        });

                        // Videos
                        const videos = this.harmony.site_settings.videos;
                        videos.forEach(video => {
                            ensureProperty(this.site_setting.videos, video.section, '');
                            this.site_setting.videos[video.section] = video.path;
                        });

                        // Platforms
                        const settings = JSON.parse(this.harmony.site_settings.setting);
                        const platforms = settings.platforms;
                        const existingPlatformNames = new Set(this.platforms.map(platform => platform.name));
                        for (const card in platforms) {
                            if (ensureProperty(platforms, card)) {
                                this.platforms_temp[card] = platforms[card];
                                let temp = {
                                    name: platforms[card].name,
                                    nick: this.extractParam(platforms[card].nick, 'model'),
                                    url: platforms[card].url,
                                    url2: platforms[card].url2,
                                    color: platforms[card].color
                                };

                                if (!('show' in platforms[card])) {
                                    temp.show = true;
                                    changesWereMade = true;
                                } else {
                                    temp.show = platforms[card].show;
                                }

                                // Añadir position y size para tarjetas 10 y superiores
                                const cardNumber = parseInt(card.replace('card', ''));
                                if (cardNumber >= 10) {
                                    temp.position = platforms[card].position;
                                    temp.size = platforms[card].size;
                                }

                                this.site_setting.platforms[card] = temp;
                            }
                        }

                        // Platforms + new platforms
                        const newPlatforms = settings.newplatforms;

                        newPlatforms.forEach(platform => {
                            if (!existingPlatformNames.has(platform.name)) {
                                if (!('show' in platform)) {
                                    platform.show = true;
                                    changesWereMade = true;
                                }
                                this.platforms.push(platform);
                                existingPlatformNames.add(platform.name);
                            }
                        });
                        this.site_setting.newplatforms = newPlatforms;

                        // Socials
                        this.socials = this.harmony.site_settings.socials;
                        this.site_setting.style_socials = settings.style_socials;
                        this.socials.forEach(social => {
                            this.$set(this.changes, social.name.toLowerCase(), false);
                        });

                        this.newSocial = { name: '', nick: '' };
                        this.style_socials_temp = settings.style_socials;
                        if (this.$route.path.includes('/editor')) {
                            await this.getSocials();
                        }

                        // Galleries
                        const galleries = this.harmony.site_settings.gallery;
                        galleries.forEach(gallery => {
                            ensureProperty(this.site_setting.galleries, gallery.section, { id: null, images: [] });
                            this.site_setting.galleries[gallery.section] = {
                                id: gallery.id,
                                images: gallery.images.map(image => image.path)
                            };
                        });

                        // Verifica si la clave 'galleries' no existe en 'settings'
                        if (!Object.prototype.hasOwnProperty.call(settings, 'galleries')) {
                            settings.galleries = this.site_setting.galleries;
                        }


                        // Resto del código...
                        for (const cardKey in settings.platforms) {
                            if (Object.prototype.hasOwnProperty.call(settings.platforms, cardKey)) {
                                ensureProperty(this.site_setting.platforms, cardKey, settings.platforms[cardKey]);
                                ensureProperty(this.site_setting.backgrounds, cardKey, settings.backgrounds[cardKey] || '');
                                ensureProperty(this.site_setting.galleries, cardKey, settings.galleries[cardKey] || { id: null, images: [] });
                                ensureProperty(this.site_setting.videos, cardKey, settings.videos[cardKey] || '');
                                ensureProperty(this.site_setting.schedules, cardKey, settings.schedules[cardKey] || { numSection: '', schedule: [] });
                            }
                        }

                        // Save settings if changes were made
                        if (changesWereMade) {
                            if (this.$route.path.includes('/editor')) {
                                await this.saveSettings();
                            }
                        }
                    } catch (error) {
                        console.error(error);
                    }

                }
        },

        async loadHarmony(slug) {
            this.checkUser()
            try {
                const response = await axios.get(`/site/${slug}`);
                this.harmony = response.data;
                this.visits = this.harmony.visits_count;

                if (this.harmony.status_id != 3){

                    if (this.user){

                       if (this.user.id != this.harmony.user_id){
                            this.$vs.notify({
                                title: this.$t('harmony.social-errorConnection-title'),
                                text: this.$t('harmony.social-errorConnection-text'),
                                color: '#fcb188',
                                icon: 'warning',
                                position: 'bottom-center',
                                time: 6000,
                            });
                            this.$router.replace({ name: 'NotFound' });
                        }

                    }else{
                        this.$vs.notify({
                            title: this.$t('harmony.social-errorConnection-title'),
                            text: this.$t('harmony.social-errorConnection-text'),
                            color: '#fcb188',
                            icon: 'warning',
                            position: 'bottom-center',
                            time: 6000,
                        });
                        this.$router.replace({ name: 'NotFound' });
                    }

                }

                this.settings = JSON.parse(response.data.site_settings.setting)
                await this.setSettings(this.settings)

            } catch (error) {
                //console.log(error)
                this.$router.replace({ name: 'NotFound' });
            }
        },
        async checkEditorPermission(slug) {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user) {
                return this.$router.replace('/');
            }

            try {
                const response = await axios.get(`/site/${slug}`);
                const site = response.data;

                if (site.user_id === user.id) {
                    this.editor = true;
                } else {
                    this.$router.replace('/');
                }
            } catch (error) {
                this.$router.replace('/');
            }
        },

        async handleVideoUploaded() {
            const slug = this.$route.params.slug;
            await this.loadHarmony(slug);
            await this.saveSettings(slug);

            try {
                const response = await axios.get('/get-my-info');
                const userData = response.data;
                localStorage.setItem('user', JSON.stringify(userData));
                this.user = response.data
            } catch (error) {
                console.error('Failed to update user info:', error);
            }
        },

        async removeVideo(value){
            try {
                const response = await axios.post('remove-video', {video: value});
                if (response.data){
                    this.$vs.notify({
                        title: this.$t('harmony.social-videoRemoved-title'),
                        text: this.$t('harmony.social-videoRemoved-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                    Object.keys(this.site_setting.videos).forEach(key => {
                        if (this.site_setting.videos[key] === value) {
                            this.site_setting.videos[key] = '';
                        }
                    });

                    if (this.site_setting.page.videos == value){
                        this.site_setting.page.videos = ''
                    }
                    this.saveSettings()
                }
            } catch (error) {
                console.log(error)
            }
        },

        async removeGallery(value){
            try {
                const response = await axios.post('remove-gallery', {gallery: value});
                if (response.data){
                    this.$vs.notify({
                        title: this.$t('harmony.gallery-title-remove'),
                        text: this.$t('harmony.gallery-text-remove'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                    Object.keys(this.site_setting.galleries).forEach(key => {
                        if (this.site_setting.galleries[key].id == value){
                            this.site_setting.galleries[key] = {id: null, images: []}
                        }
                    });
                    this.saveSettings()
                }
            } catch (error) {
                console.log(error)
            }
        },


        removeNamePlatform(cardName){
            this.site_setting.platforms[cardName].name = ''
        },


        updateNick() {
            if (!this.selectedCard || !this.selectedCard.name || !this.selectedCard.nick) {
                return;
            }
            const platformName = this.selectedCard.name;
            const foundPlatform = this.site_setting.newplatforms.find(platform => platform.name === platformName);

            if (foundPlatform) {
                foundPlatform.nick = this.selectedCard.nick;
            }
        },
        countSocials() {
            return this.socials.length
        },

        hasImages(gallery) {
            return gallery && gallery.images && gallery.images.length > 0;
        },

        addNewImagesGallery(){
           this.galleryUpdated = true
           this.$refs.imageUploadGallery.triggerFileInput()
        },

        async addNewCard(){
        const cardNumber = Object.keys(this.site_setting.platforms).length + 1;
        const newCardKey = `card${cardNumber}`;
        const newCard = { name: '', nick: '', color: 1, url: '', url2: '', show: true, position: this.position, size: this.size };

        try {
                this.$set(this.site_setting.platforms, newCardKey, newCard);
                this.$set(this.site_setting.backgrounds, newCardKey, '');
                this.$set(this.site_setting.galleries, newCardKey, { id: null, images: [], });
                this.$set(this.site_setting.videos, newCardKey, '');
                this.$set(this.site_setting.schedules, newCardKey, { numSection: '', schedule: [], });
                console.log(this.site_setting)

                if (this.$route.path.includes('/editor')) {
                    await this.saveSettings();
                }
            } catch (error) {
                console.log(error)
            }
        },

        getSizeCard(value){
            if (value == 'small'){
                return 'card2-container'
            }else if (value == 'medium'){
                return 'card-container'
            }else if (value == 'large'){
                //console.log('large')
                return 'video-container'
            }
        },
        getClass(value){
            if (value == 'small'){
                return 'card2'
            }else if (value == 'medium'){
                return 'card'
            }else if (value == 'large'){
                return 'video'
            }
        },
        hasSchedule(card) {
            return card && card.schedule && card.schedule.length > 0;
        },
        hasScheduleNum(card) {
            return card && card.numSection.length > 0;
        },
        saveSchedule() {
            this.loadingSchedules = true
            let startHour = this.selectedStartHour + ':00'
            let endHour = this.selectedEndHour + ':00'
            if(this.selectedStartHour.replace(':','') > this.selectedEndHour.replace(':','')){
                startHour = this.selectedEndHour + ':00'
                endHour = this.selectedStartHour + ':00'
            }
            const newDetail = {
            name_day: this.name_day,
            start_time: startHour,
            end_time: endHour,
            url: this.urlSchedule
            };
            this.$refs.UploadSchedule.uploadSchedule(newDetail);
            this.onAddScheduleClosed();
            this.addSchedule = false;
            this.loadingSchedules = false
        },
   
        onAddScheduleClosed() {
            this.name_day = '';
            this.urlSchedule = '';
            this.selectedStartHour = '';
            this.selectedEndHour = '';
            this.$refs.selectedStartHour.resetValidation();
            this.$refs.selectedEndHour.resetValidation();
            this.$refs.filteredDayOptions.resetValidation();
            this.$refs.urlSchedule.resetValidation();
        },
        setDayOptions(){
            this.dayOptions = [{ id: 1, name: this.$t('harmony.day-1') },
                { id: 2, name: this.$t('harmony.day-2') },
                { id: 3, name: this.$t('harmony.day-3') },
                { id: 4, name: this.$t('harmony.day-4') },
                { id: 5, name: this.$t('harmony.day-5') },
                { id: 6, name: this.$t('harmony.day-6') },
                { id: 7, name: this.$t('harmony.day-7') }]
        },
        getUrlSchedule(sch) {
            const now = new Date();
            const currentDay = (now.getDay() + 1).toString();

            if (sch != null && Array.isArray(sch) && sch.length > 0) {
                let event = sch.find(event => event.name_day === currentDay);

                if (!event) {
                    const sortedEvents = sch.sort((a, b) => parseInt(a.name_day) - parseInt(b.name_day));
                    event = sortedEvents.find(event => parseInt(event.name_day) > parseInt(currentDay));
                    if (!event) {
                        event = sortedEvents[0];
                    }
                }
                if (event && event.url) {
                    return event.url.startsWith('http://') || event.url.startsWith('https://')
                        ? event.url
                        : 'https://' + event.url;
                }
            }

            return '';
        }
    },
    directives: {
    focus: {
      inserted(el) {
        el.focus()
      },
    },
  },
}
</script>

<style scoped>

    .patternDiv.selected {
    border: 2px solid #000000; 
    border-radius: 8px;
    }

   .v-btn ::v-deep .v-input--selection-controls__input {
        margin: 0;
    }
    .checkbox-on {
        color: #9BA9BD !important;
    }

    .checkbox-off {
        color: #d3d3d3 !important;
    }

    .harmony-button {
        position: fixed;
        top: 12px;
        left: 25px;
        z-index: 90;
        cursor: pointer;
    }

    .views {
        position: fixed;
        top: 14px;
        right: 20px;
        z-index: 90;
        font-size: 20px;
        margin-right: 2px;
    }
    .harmony-button img {
        width: 30px;
    }
    .gallery-component-card {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1000;
    }

    @media (width <=600px) {
        .harmony-button img {
                width: 30px !important;
        }
        .views {
            font-size: 12px;
            right: 20px;
        }
    }
    .responsive-text {
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word;
        font-size: 1rem;
    }

    @media (max-width: 354px) {
        .responsive-text {
            font-size: 0.875rem; /* Ajusta el tamaño de la fuente para que sea más pequeño */
            line-height: 1.2; /* Ajusta la altura de la línea para mayor claridad */
        }

        .responsive-text v-icon {
            vertical-align: middle; /* Alinea el ícono correctamente con el texto */
        }
    }

    .bg-white-title {
        padding-top: 6px;
        padding-bottom: 4px;
        border-radius: 7px;
        background-color: white;
    }

    .bg-white {
        padding-top: 6px;
        padding-bottom: 4px;
        border-radius: 7px;
        background-color: white;
    }

    .card-text-shadow {
        text-shadow: #00000054 0px 0px 5px;
    }

    .card-shadow-logo {
        box-shadow: #878282 0px 1px 6px 0.1px;
    }

    .card-shadow-socials {
        box-shadow: #0000002b 0px 1px 10px 1.3px;
    }

    .card-shadow {
        box-shadow: #00000061 0px 1px 6px 1.3px;
    }

    .video-background::-webkit-media-controls {
        display: none !important;
    }


    .icon-redes {
        width: 24px;
        margin: auto;
    }

    .video-background::-webkit-media-controls-start-playback-button {
        display: none !important;
    }

    .video-background::-webkit-media-controls-overlay-play-button {
        display: none !important;
    }

    .video-background::-webkit-media-controls-volume-slider {
        display: none !important;
    }

    .button-footer {
    background-color: #ffffff;
    font-size: 11px;
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    }

    @media (min-width: 321px) and (max-width: 400px) {
        .button-footer {
            font-size: 0.6rem;
            padding: 0.4rem;
        }
    }

    @media (max-width: 320px)  and (min-width: 100px) {
        .button-footer {
            font-size: 0.5rem;
            padding: 0.2rem;
        }
    }

    .a-footer {
        color: inherit;
        text-decoration: none;
    }

    .patternDiv {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .patternDiv img.icon {
        width: 24px;
        height: 24px;
    }

    .newPlatform {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
    }

    .white h1 {
            color: white !important;
    }

    .black h1 {
            color: black !important;
    }

    .cursor {
        cursor: pointer;
    }

    .floating-drawer {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;
    }
    .bg-platform {
        background-color: #6b6b6b;
    }
    .bg-platform2 {
        background-color: #dfdbdb;
    }

    .card-container {
        position: relative;
        height: 180px;
    }

    .video-container {
        position: relative;
        height: 503px;
    }

    .card2-container {
        position: relative;
        height: 130px;
    }

    .platform-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 2;
    }
    .platform-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0 10px;
            white-space: normal;
            word-wrap: break-word;
            overflow: hidden;
            font-size: clamp(16px, 5vw, 28px);
    }

    .patterns-div {
        height: 85px;
        overflow: auto;
    }

    .platforms-div {
        height: 104px;
        overflow: auto;
    }

    .socials-div {
        height: 65px;
        overflow: auto;
    }

    .selected-box {
        border: 2px solid #8e84c0;
        border-radius: 50%;
        padding: 5px;
        margin-top: 5px;
    }
    .selected-icon {
        color: #8e84c0;
    }
    .platforms {
        width: 165px;
    }

    .base-socials {
        background-color: white;
    }

    .base-socials-editor {
        cursor: pointer;
        background-color: white;
    }

    .socials-icon {
        width: 30px;
    }

    .socials-icon2 {
        width: 40px;
    }

    .icon-size-smallest {
        width: 35px !important;
    }

    .icon-size-small {
        width: 30px !important;
    }

    .icon-size-medium {
        width: 34px !important;
    }

    .icon-size-large {
        width: 40px !important;
    }

     @media(width >= 960px) {
        .icon-size-medium {
            width: 50px !important;
        }
        .icon-size-small {
            width: 50px !important;
        }
        .icon-size-large {
            width: 50px !important;
        }
        .icon-size-smallest {
            width: 40px !important;
        }

    }

    @media (width > 430px) and (width < 959px) {
        .icon-size-medium {
            width: 50px !important;
        }
        .icon-size-small {
            width: 35px !important;
        }
    }


    @media (width <=350px){
        .icon-size-medium {
            width: 28px !important;
        }
        .icon-size-small {
            width: 30px !important;
        }
    }

    .frame-div {
        border-radius: 8px;
        border: 2px solid rgb(201, 201, 201);
    }

    .loading-spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    .menu-text {
        font-size: 14px;
        color: #9BA9BD;
    }

    .card2 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 130px;
    }
    .background-media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
    }

    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
    }

    .video {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 503px;
    }

    .socials {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60px;
        /*overflow: auto;*/
    }

    .base {
        cursor: pointer;
        background-color: #E8E8E8;
    }

    .sections {
        display: flex;
        flex-direction: column;
        width: 282px;
    }

    .sections-container {
        display: flex;
        flex-wrap: nowrap;
    }

    .logo {
        cursor: pointer;
        height: 100px;
        width: 100px;
        background-color: #E8E8E8;
    }

    .logo2 {
        height: 100px;
        width: 100px;
        background-color: #E8E8E8;
    }

    .logo .v-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 959px) {
        .socials-icon2 {
            width: 30px;
        }

        .logo {
            height: 90px;
            width: 90px;
        }

        .title-harmony {
            font-size: 26px;
        }
    }

    @media (max-width: 577px) {
        .sections {
            width: 260px;
        }

        .socials-icon2 {
            width: 30px;
        }
    }

    @media (max-width: 530px) {
        .sections {
            width: 240px;
        }
    }

    @media (max-width: 496px) {
        .sections {
            width: 225px;
        }

        .socials-icon2 {
            width: 27px;
        }
    }

    @media (max-width: 462px) {
        .sections {
            width: 208px;
        }
    }

    @media (max-width: 432px) {
        .sections {
            width: 199px;
        }
    }

    @media (max-width: 410px) {
        .sections {
            width: 188px;
        }
    }

    @media (max-width: 410px) {
        .sections {
            width: 188px;
        }
    }

    @media (max-width: 400px) {
        .sections {
            width: 180px;
        }

        .socials-icon2 {
            width: 24px;
        }
    }

    @media (max-width: 372px) {
        .sections {
            width: 170px;
        }
    }

    @media (max-width: 354px) {
        .sections {
            width: 160px;
        }
    }

    @media (max-width: 330px) {
        .sections {
            width: 150px;
        }
    }

    @media (max-width: 306px) {
        .sections {
            width: 140px;
        }
    }

    .page-background {
        position: relative;
        width: 100%;
        min-height: 100vh;
    }

    .background {
        position: relative;
        z-index: 1;
        background-size: cover;
        background-position: center;
        background-repeat: repeat;
        min-height: 100vh;
    }

    .background::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        background-repeat: repeat;
        opacity: 0.5;
        z-index: -1;
        width: 100%;
    }

    .background-opacity::before {
        opacity: 0.5;
    }

    .video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }
    .my-custom-select .v-input__control{
        background-color: #f0f0f0;
        color: #666;
        border: 1px solid #ccc;
        display: none;
    }

    .my-custom-select ::v-deep .v-input__control {
        max-height: 28px !important;
        min-height: 28px !important;
    }

    .my-custom-select ::v-deep .v-select__selections {
        max-height: 50px !important;
        min-height: 50px !important;
        line-height: 28px !important;
        color: #9BA9BD !important;
        font-size: 14px !important;
    }

    .my-custom-select ::v-deep .v-input__slot {
        max-height: 28px !important;
        min-height: 28px !important;

    }

    .my-custom-select ::v-deep .v-input__slot  .v-input__append-inner{
        margin-top: 1px !important;
        width: 18px !important;
    }

    .my-custom-select ::v-deep .v-select__input {
        min-height: 28px !important;
        height: 28px !important;
        line-height: 28px !important;
    }
    .my-custom-select {
        height: 28px !important;
    }

    .my-custom-select   ::v-deep  .v-input__icon--append{
        margin-top: auto;
        margin-bottom: auto;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .my-custom-select  ::v-deep  .v-input__icon--append .v-icon{
        margin: auto;
    }

    .my-custom-select ::v-deep .v-list-item--active {
        background-color: #8e84c0 !important; /* Cambia el color de resaltado aquí */
    }

    .emoticon{
        cursor: pointer;
    }
    .wrapper {
        position: relative;
        display: inline-block;
    }

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66,153,225,.5);
}

.emoji-invoker {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s;
        padding: 0;
        background: transparent;
        border: 0;
    }

    .emoji-invoker:hover {
        transform: scale(1.1);
    }

    .emoji-invoker > svg {
        fill: #b1c6d0;
    }

    .emoji-picker {
        position: relative;
        font-family: Montserrat;
        border: 1px solid #ccc;
        width: 100%;
        max-width: 100%;
        height: 20rem;
        overflow: scroll;
        padding: 1rem;
        box-sizing: border-box;
        border-radius: 0.5rem;
        background: #fff;
        box-shadow: 1px 1px 8px #c7dbe6;
        margin-top: 0.5rem;
    }

    .emoji-picker__search {
        display: flex;
    }

    .emoji-picker__search > input {
        flex: 1;
        border-radius: 10rem;
        border: 1px solid #ccc;
        padding: 0.5rem 1rem;
        outline: none;
    }

    .emoji-picker h5 {
        margin-bottom: 0;
        color: #b1b1b1;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: default;
    }

    .emoji-picker .emojis {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .emoji-picker .emojis:after {
        content: "";
        flex: auto;
    }

    .emoji-picker .emojis span {
        padding: 0.2rem;
        cursor: pointer;
        border-radius: 5px;
    }

    .emoji-picker .emojis span:hover {
        background: #ececec;
    }

    textarea {
        padding: 0.5rem 1rem;
        border-radius: 3px;
        border: 1px solid #ccc;
        width: 100%;
        height: 12rem;
        outline: none;
        box-sizing: border-box;
    }

    textarea:focus {
        box-shadow: 0 0 0 3px rgba(66,153,225,.5);
    }



</style>

<style>
    body {
        margin: 0;
        overflow: hidden;
    }

    .options .v-expansion-panel-content__wrap {
        padding: 0px !important;
    }

    .v-picker, .fit, .v-dialog{
        width: fit-content !important;
    }

    .border-timer{
        border: 1px solid #8e84c0;
        border-radius: 5px;
        padding: 10px 15px !important;
    }
</style>
